import React, {FC, useCallback, useState} from 'react';

import {ITrack} from '../modules/rest';
import './Player.scss';
import {ReactSVG} from "react-svg";
import pause_svg from "../assets/images/icons/pause.svg";
import play_svg from "../assets/images/icons/play.svg";
import {thumb} from "../modules/utils";

interface Props {
  track: ITrack;
  onChange?: (id?: number) => void;
  active?: boolean;
  small?: boolean;
  className?: string;
}

const Player: FC<Props> = ({track, onChange, className, active, small}) => {
  const [localActive, setLocalActive] = useState(false);
  const click = useCallback((e: any) => {
    e.stopPropagation();
    if (onChange) {
      onChange(active ? undefined : track.id);
    } else {
      setLocalActive(prevState => !prevState);
    }
  }, [onChange, track.id, active]);

  const activePlay = active ||localActive

  return (
    <div onClick={click} className={`player${activePlay ? ' player-active' : ''}${small ? ' small' : ''} ${className || ''}`}>
      <img src={thumb(track?.album?.cover?.id!, 200)} alt="cover"/>
      <div className='player-controls'>
        {activePlay
          ?
          <>
            <audio
              src={track?.sample?.url!}
              autoPlay={true}
              controls={false}
              loop
            />
            <ReactSVG src={pause_svg} className='react-icon'/>
          </>
          :
          <ReactSVG src={play_svg} className='react-icon'/>
        }
      </div>
      {/*{active ? (*/}
      {/*  <>*/}
      {/*    <div className="ctrl ctrl-animation">*/}
      {/*      <div className="box">*/}
      {/*        <div className="bar"/>*/}
      {/*        <div className="bar"/>*/}
      {/*        <div className="bar"/>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="ctrl ctrl-pause">*/}
      {/*      <Icon icon={['fas', 'pause']} style={{fontSize: 24}}/>*/}
      {/*    </div>*/}
      {/*    <audio src={track.sample?.url!} autoPlay={true} controls={false} loop/>*/}
      {/*  </>*/}
      {/*) : (*/}
      {/*  <>*/}
      {/*    <div className="player-id">{track.id}</div>*/}
      {/*    <div className="ctrl ctrl-play">*/}
      {/*      <Icon icon={['fas', 'play']} style={{fontSize: 24}}/>*/}
      {/*    </div>*/}
      {/*  </>*/}
      {/*)}*/}
    </div>
  );
};

export default Player;
