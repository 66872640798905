import React, {FC, useCallback} from 'react';

interface Props {
  label: string;
  className?: string;
  text: string;
}

const TextGenerateResultItem: FC<Props> = ({className, text, label}) => {

  const handleCopy = useCallback(() => {
    navigator?.clipboard?.writeText(text);
  }, [text]);

  return (
    <div className={`text-generate-result-item ${className || ''}`}>
      <div className="text-medium-14 mb-2 text-muted">{label}</div>
      <div className="text-generate-result-text-wrap">
        <div className="text-generate-result-text">{text}</div>
        <div className="circle-btn copy" onClick={handleCopy} />
      </div>
    </div>
  );
}

export default TextGenerateResultItem;