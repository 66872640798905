import React, {FC, useCallback, useRef, useState} from 'react';
import {EReviewStatus, IVideoEmbed} from "../../modules/rest";
import {formatViews} from "../../modules/utils";
import trash_svg from "../../assets/images/icons/trash.svg";
import views_svg from '../../assets/images/icons/views.svg';
import {ReactSVG} from "react-svg";
import {useTranslation} from "react-i18next";
import {openTracksVideosModal} from "../../modals";
import {addVideoModal} from "../../modals/videos/AddVideoToTrackModal";
import Loadable from "../../components/Loadable";
import {API} from "../../modules/api";
import {confirmDialog} from "../../modules/confirm";
import {toast} from "react-toastify";
import Video2 from "../../components/Video2";

interface Props {
  ve: IVideoEmbed;
  activeTrackId: null|number;
  toggleActiveTrackId: (id: number|null) => void;
  fetch: () => void;
}

const TracksListItem: FC<Props> = ({
                                     ve,
                                     activeTrackId,
                                     toggleActiveTrackId,
                                     fetch,
                                   }) => {
  const videoRef: any = useRef(null);
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false)

  const active = ve.id === activeTrackId;

  const handleOpenVideosModal = useCallback(() => {
    openTracksVideosModal(ve).then(fetch);
    toggleActiveTrackId(null);
  }, [fetch, toggleActiveTrackId, ve]);


  const handleOpenAddVideoModal = useCallback((e: any) => {
    e.stopPropagation();
    addVideoModal(ve).then(fetch);
  }, [fetch, ve]);


  const handleRemoveRejectedVideo = useCallback(() => {
    confirmDialog(t('DELETE_CLIP_CONFIRM'), {
      title: t('DELETING_CLIP') || '',
      danger: true,
      confirmText: t('REMOVE') || '',
    }).then(async (confirmed) => {
      if (!confirmed) return;
      setLoading(true)
      try {
        await API.VideoEmbeds.deleteVideoEmbed(ve.id);
        toast.success(t('CLIP_REMOVED'));
        fetch();
      } catch (e: any) {
        toast.error(e);
      } finally {
        setLoading(false);
      }
    });
  }, [fetch, ve.id]);


  if (ve.status === EReviewStatus.Review) return (
    <div className='tracks-list-item pending'>
      {t('VIDEO_PENDING')}
    </div>
  )
  if (ve.status === EReviewStatus.Reject) return (
    <Loadable loading={loading} className='tracks-list-item reject'>
      <div>
        {ve.rejectReason}
      </div>
      <button className='btn btn-danger btn-sm ms-3 p-0' onClick={handleRemoveRejectedVideo}>
        <ReactSVG src={trash_svg} className='react-icon'/>
      </button>
    </Loadable>
  )

  return (
    <div className={`tracks-list-item${active ? ' active' : ''}`} onClick={handleOpenVideosModal}>
      <div className="tracks-list-item-content">
        <Video2
          active={active}
          onChangeActiveVideo={() => toggleActiveTrackId(ve.id)}
          ref={videoRef}
          className='catalog-list-item-video'
          ve={ve}
        />
        <div className="d-flex align-items-center justify-content-between text-medium mt-2 p-1">
          <div className='d-flex align-items-center tracks-list-item-videoCount' onClick={handleOpenAddVideoModal}>
            <span className='text-muted-14 tracks-list-item-video add'>{t('ADD_VIDEO')}</span>
            <span
              className='text-muted-14 tracks-list-item-video count text-lowercase'>{ve.videoCount} {t('VIDEO')}</span>
          </div>
          <div className='d-flex align-items-center'>
            <ReactSVG src={views_svg} className='react-icon'/>
            <span className='ps-2 text-muted-14'>{formatViews(ve.views!)}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TracksListItem;