import {CSSProperties, FC} from 'react';
import {ReactSVG} from "react-svg";
import close from '../assets/images/icons/X.svg';

interface Props {
  onClick: () => void;
  style?: CSSProperties
}

const ModalClose: FC<Props> = ({onClick, style}) => {
  return (
    <div className="modal-close" onClick={onClick} style={style}>
      <ReactSVG src={close} className='react-icon'/>
    </div>
  );
};

export default ModalClose;
