import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-modal/styles.css';
import './assets/fonts/fonts.css'
import './assets/scss/constants.css'
import './assets/scss/main.scss'
import './assets/scss/media.scss'
import './i18n';
import App from './App';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorFallback';
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import moment from "moment";
import {getLang} from "./modules/utils";
import {ReactSVG} from "react-svg";
import close_svg from './assets/images/icons/close.svg';
moment.locale(getLang());

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <App />
    </ErrorBoundary>
    <ToastContainer
      // icon={<ReactSVG src={danger_svg}/>}
                    closeButton={<ReactSVG src={close_svg}/>}
    />
  </BrowserRouter>
);
