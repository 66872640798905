import React, {FC, useCallback, useState} from 'react';
import Avatar from "../Avatar";
import session from "../../modules/session";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {Icon} from "../fontawesome";
import OutsideClick from "../OutsideClick";

interface Props {
}

const HeaderProfile: FC<Props> = () => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen(prevState => !prevState);
  }, []);

  return (
    <OutsideClick outsideClickEvent={() => setIsOpen(false)}  className={`header-profile header-popover${isOpen ? ' active' : ''}`}>
      <div onClick={toggle} className='cursor-pointer'>
        <Avatar letter={session.user?.email} className='ms-3'/>
      </div>
      {isOpen
        ?
        <div className="header-popover-content">
          <div className='d-flex align-items-center p-2'>
            <Avatar letter={session.user?.email}/>
            <div className='ms-2 ps-1 overflow-hidden d-grid'>
              <div className='text-muted-12 text-truncate'>{session.user?.email}</div>
            </div>
          </div>
          {/*<div className='header-profile-hidden-settings p-2'>*/}
          {/*  <Icon icon={['fas', 'cog']} size={'xl'}/>*/}
          {/*  <span className='ms-2 bold'>{t('SETTINGS')}</span>*/}
          {/*</div>*/}
          <button className="mt-3 btn " onClick={session.logout} type='button'>
            <div className='btn-icon'>
              <Icon icon={['fas', 'sign-out-alt']}/>
            </div>
            <span>{t('LOGOUT')}</span>
          </button>
        </div>
        :
        null
      }

    </OutsideClick>
  );
}

export default observer(HeaderProfile);