import React, {FC, useCallback, useState} from 'react';
import {IVideoEmbed} from "../../modules/rest";
import Loader from "../../components/Loader";
import TracksListItem from "./TracksListItem";
import {ReactSVG} from "react-svg";
import cassette from '../../assets/images/icons/cassette.svg';
import {useTranslation} from "react-i18next";
import get_track_svg from "../../assets/images/icons/get_track.svg";
import Button from "../../components/Button";

interface Props {
  tracks?: IVideoEmbed[];
  onRequestTrack: () => void;
  fetch: () => void;
}

const TracksList: FC<Props> = ({tracks, onRequestTrack, fetch}) => {
  const {t} = useTranslation();
  const [activeTrackId, setActiveTrackId] = useState<number|null>(null);

  const toggleActiveTrackId = useCallback((id: number | null) =>   {
    setActiveTrackId(prevState => {
      if (prevState === id) return null;
      return id;
    })
  }, []);


  if (!tracks) return <Loader/>
  if (!tracks?.length) return <div className='d-flex flex-column align-items-center'>
    <ReactSVG src={cassette}  className='react-icon'/>
    <h4 className='mt-1 mb-3 text-muted'>{t('TRACKS_LIST_EMPTY')}</h4>
    <Button
      size={'lg'}
      text='REQUEST_TRACK'
      iconSvg={get_track_svg}
      onClick={onRequestTrack}
    />
  </div>

  return (
    <div className='tracks-list'>
      {tracks.map(ve => (
        <TracksListItem
          fetch={fetch}
          key={ve.id}
          ve={ve}
          activeTrackId={activeTrackId}
          toggleActiveTrackId={toggleActiveTrackId}
        />
      ))}
    </div>
  );
}

export default TracksList;