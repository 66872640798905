import React, {FC, useCallback, useEffect} from 'react';
import Button from "../../components/Button";
import {useTranslation} from "react-i18next";
import {observer, useLocalObservable} from "mobx-react";
import {runInAction} from "mobx";
import {toast} from "react-toastify";
import {API} from "../../modules/api";
import {EFieldGroup, IGetVideoEmbedsRequest, IPagedData, IVideoEmbed} from "../../modules/rest";

import get_track_svg from '../../assets/images/icons/get_track.svg';

import './assets/tracks.scss'
import TracksList from "./TracksList";
import {openRequestTrackModal} from "../../modals";
import Loadable from "../../components/Loadable";
import Pagination from "../../components/Pagination";
import VideosListEmpty from "./VideosListEmpty";

interface State {
  pager?: IPagedData<IVideoEmbed>;
  request: IGetVideoEmbedsRequest;
  loading: boolean;
  activeTrackId?: number;
}

const TracksPage: FC = () => {
  const {t} = useTranslation();

  const st = useLocalObservable<State>(() => ({
    loading: true,
    request: {
      page: 1,
      limit: 1000
    },
    author: null,
  }));

  useEffect(() => {
    fetch()
  }, [])

  const fetch = useCallback(() => {
    runInAction(() => (st.loading = true));
    API.VideoEmbeds.getList(st.request, [
      EFieldGroup.VideoEmbedStatus,
      EFieldGroup.VideoEmbedStats,
      EFieldGroup.VideoEmbedVideo,
      EFieldGroup.AlbumLinks,
      EFieldGroup.VideoEmbedLanguage,
      EFieldGroup.AlbumTracks
    ])
      .then((pager) => {
        runInAction(() => {
          st.pager = pager;
          st.request.page = pager.page;
          st.request.limit = pager.limit;
        });
      })
      .catch(toast.error)
      .finally(() => runInAction(() => (st.loading = false)));
  }, [st.request]);


  const handleRequestTrack = useCallback(() => {
    openRequestTrackModal().then(res => {
      if (res) fetch();
    })
  }, [fetch]);

  if(!st.pager?.data) return null;
  if (!st.pager?.data?.length) return <VideosListEmpty onClick={handleRequestTrack}/>
  // return <VideosListEmpty onClick={handleRequestTrack}/>
  return (
    <div>
      <div className='page-header'>
        <h1>{t('MY_VIDEOS')}</h1>
        <Button
          text='REQUEST_VIDEO'
          iconSvg={get_track_svg}
          onClick={handleRequestTrack}
        />
      </div>
      <Loadable className='mt-4' loading={st.loading}>
        <TracksList tracks={st.pager?.data} onRequestTrack={handleRequestTrack} fetch={fetch}/>
        <Pagination
          className='mb-2'
          all
          onPageChange={(page) =>
            runInAction(() => {
              st.request.page = page;
              fetch();
            })
          }
          onLimitChange={(limit) =>
            runInAction(() => {
              st.request.page = 1;
              st.request.limit = limit;
              fetch();
            })
          }
          pager={st.pager}
        />
      </Loadable>
    </div>
  );
}

export default observer(TracksPage);