import {ELanguage, IGenerateVideoEmbedTextsTask, IVideoEmbed} from '../../modules/rest';
import asyncModal from 'react-async-modal';
import React, {FC, useCallback, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import ModalClose from "../ModalClose";
import ImportantBlock from "../../components/ImportantBlock";
import TextGenerate from "../../components/TextGenerate";
import Video from "../../components/Video2";

interface Props {
  ve: IVideoEmbed;

  resolve(): void;
}

const DownloadVideoModal: FC<Props> = ({ve, resolve}) => {
  const videoRef: any = useRef(null);
  const {t} = useTranslation();
  const [generateTexts, setGenerateTexts] = useState<any>({});

  const handleClose = useCallback(() => {
    resolve()
  }, [resolve]);

  const handleUpdateGenTexts = useCallback((text: IGenerateVideoEmbedTextsTask, language: ELanguage) => {
    setGenerateTexts((prevState: any) => ({...prevState, [language]: text}));
  }, []);


  return (
    <div className='modal-without-padding download-video-modal'>

      <div className='videos-modal-header flex-column p-0 mb-1'>
        <div className='d-flex w-100 p-1 mb-1 align-items-end'>
          <Video
            downloadModal={false}
            local
            ref={videoRef}
            className='w-auto'
            ve={ve}
          />
          <div className='download-video-modal-title'>
            <h2>{t('DOWNLOAD_VIDEO_TEXT')}</h2>
          </div>
        </div>
        <div className='w-100 p-3 position-relative'>
          <TextGenerate
            videoEmbedId={ve.id}
            onUpdate={handleUpdateGenTexts}
            generateTexts={generateTexts}
          />
        </div>
      </div>

      {/*<TrackCover dt={dt} toggleActiveTrackId={toggleActiveTrack} active={activeTrack} download/>*/}
      <div className='p-1'>
      <ImportantBlock title='VIDEO_EMBED_IMPORTANT_TITLE' text={'VIDEO_EMBED_IMPORTANT_TEXT'}/>
      </div>
      <ModalClose onClick={handleClose}  style={{top:24, right: 24}}/>
    </div>
  );
};

const openDownloadVideoModal = (ve: IVideoEmbed): Promise<void> => asyncModal(DownloadVideoModal, {ve}, {
  showCloseIcon: false,
  center: true,
  styles: {modal: {width: 750}}
});

export {openDownloadVideoModal};
