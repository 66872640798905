import React, {FC} from 'react';
import {Icon} from './fontawesome';
import {IPagedData} from '../modules/rest';
import {Select} from '../components/FormControls';
import {useTranslation} from "react-i18next";

interface Props {
  all?: boolean;
  limits?: number[];
  pager?: IPagedData<any>;
  textType?: string;
  className?: string;

  onPageChange(page: number): void;

  onLimitChange?(limit: number): void;
}

const Pagination: FC<Props> = ({
                                 pager,
                                 textType,
                                 limits = [15, 50, 100, 500, 1000],
                                 onPageChange,
                                 className,
                                 onLimitChange,
                                 all,
                               }) => {
  const {t} = useTranslation();

  if (pager?.pages! <= 1 || !pager) return null;
  // if (  !pager) return null;

  return (
    <>
      <hr className='mt-0'/>
      <div className={`pagination ${className || ''}`}>
        <div className='d-flex flex-nowrap'>
          <button
            className="btn btn-sm"
            disabled={pager.page <= 1}
            onClick={() => onPageChange(pager.page - 1)}
          >
            <Icon icon={['fas', 'arrow-left']} fixedWidth/>
          </button>
          <button
            className="btn btn-sm ms-2"
            disabled={pager.pages !== null && pager.page >= pager.pages}
            onClick={() => onPageChange(pager.page + 1)}
          >
            <Icon icon={['fas', 'arrow-right']} fixedWidth/>
          </button>
        </div>
        <span className='ps-3'>
        {t('PAGE')}: {pager.page}
          {pager.count !== null && <> {t('of')} {pager.pages}</>}
      </span>
        <div className='d-flex align-items-center pe-1 flex-grow-1 justify-content-end'>
          <div>{t(textType || 'RECORDS')}</div>
          {!!onLimitChange && (
            <Select
              className='ms-2'
              value={pager.limit}
              style={{maxWidth: 80}}
              onChange={(e) => onLimitChange(Number(e.target.value))}
            >
              {!limits.includes(pager.limit) && <option value={pager.limit}>{pager.limit}</option>}
              {limits.map((limit) => (
                <option key={limit} value={limit}>
                  {limit}
                </option>
              ))}
              {/*{all ? <option value={9999999}>{t('ALL')}</option> : null}*/}
            </Select>
          )}
          {pager.count !== null && <span className='ps-2 text-nowrap'>{t('of')} {pager.count}</span>}
        </div>
      </div>
    </>
  );
};

export default Pagination;
