export type THttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export type THeaders = Record<string, string>;

export type TValidationError = {
  key: string;
  name: string;
  error: string;
};

export type TResponse = {
  result?: any;
  error?: string;
  code?: number;
  validation?: TValidationError;
};

class RestAPI {
  private readonly url: string;
  private token: string | null = null;
  private statusCode: number = 0;
  private instances: Record<string, object> = {};
  private authErrorHandler?: () => void;
  private headersHandler?: (headers: THeaders) => void;
  public validation?: TValidationError;
  public debug: boolean = false;

  constructor(url: string, debug: boolean) {
    this.url = url;
    this.debug = debug;
  }

  public getUrl = (): string => {
    return this.url;
  };

  setAuthErrorHandler = (handler?: () => void) => {
    this.authErrorHandler = handler;
  };

  setHeadersHandler = (handler?: (headers: THeaders) => void) => {
    this.headersHandler = handler;
  };

  setToken = (token: string | null): this => {
    this.token = token;
    return this;
  };

  getToken = (): string | null => {
    return this.token;
  };

  getStatusCode = (): number => {
    return this.statusCode;
  };

  get = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("GET", endpoint, payload, fields);
  };

  post = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("POST", endpoint, payload, fields);
  };

  put = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("PUT", endpoint, payload, fields);
  };

  patch = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("PATCH", endpoint, payload, fields);
  };

  delete = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("DELETE", endpoint, payload, fields);
  };

  private request = <T>(
    method: THttpMethod,
    endpoint: string,
    payload: object | FormData = {},
    fields: string[] = []
  ): Promise<T> => {
    // @ts-ignore
    return new Promise((resolve, reject) => {
      const processReject = (
        error: string,
        code: number,
        validation?: TValidationError
      ) => {
        this.validation = validation;
        if (this.debug) console.error("Error", error, validation);
        if (code === 401 && this.authErrorHandler) this.authErrorHandler();
        else reject(error);
      };

      const options: {
        method: string;
        headers: THeaders;
        body?: FormData | string;
      } = {
        method: method.toUpperCase(),
        headers: {
          accept: "application/json",
        },
      };

      if (payload instanceof FormData) {
        payload.append("fields", fields.join(","));
        options.body = payload;
      } else {
        options.headers["content-type"] = "application/json";
        // @ts-ignore
        payload["fields"] = fields;
        if (payload && method !== "GET") options.body = JSON.stringify(payload);
      }

      if (this.token) {
        options.headers["authorization"] = "Bearer " + this.token;
      }

      this.statusCode = 0;
      this.validation = undefined;

      if (payload && method === "GET") {
        endpoint += "?__payload=" + encodeURIComponent(JSON.stringify(payload));
      }

      if (this.debug)
        console.log(
          "Request",
          method,
          endpoint.split("?")[0],
          JSON.parse(JSON.stringify(payload))
        );

      if (this.headersHandler) {
        this.headersHandler(options.headers);
      }

      fetch(this.url + endpoint, options)
        .then((response) => {
          this.statusCode = response.status;
          response
            .json()
            .then((data: TResponse) => {
              if (data.error)
                processReject(data.error, response.status, data.validation);
              else {
                if (this.debug) console.info("Result", data.result);
                resolve(data.result);
              }
            })
            .catch((e) => processReject(e, -2));
        })
        .catch((e) => processReject(e, -1));
    });
  };

  get Batches(): Batches {
    return (
      (this.instances["Batches"] as Batches) ??
      (this.instances["Batches"] = new Batches(this))
    );
  }

  get Badges(): Badges {
    return (
      (this.instances["Badges"] as Badges) ??
      (this.instances["Badges"] = new Badges(this))
    );
  }

  get Billing(): Billing {
    return (
      (this.instances["Billing"] as Billing) ??
      (this.instances["Billing"] = new Billing(this))
    );
  }

  get VideoEmbeds(): VideoEmbeds {
    return (
      (this.instances["VideoEmbeds"] as VideoEmbeds) ??
      (this.instances["VideoEmbeds"] = new VideoEmbeds(this))
    );
  }

  get Melomania(): Melomania {
    return (
      (this.instances["Melomania"] as Melomania) ??
      (this.instances["Melomania"] = new Melomania(this))
    );
  }

  get Users(): Users {
    return (
      (this.instances["Users"] as Users) ??
      (this.instances["Users"] = new Users(this))
    );
  }

  get VideoContent(): VideoContent {
    return (
      (this.instances["VideoContent"] as VideoContent) ??
      (this.instances["VideoContent"] = new VideoContent(this))
    );
  }

  get Assets(): Assets {
    return (
      (this.instances["Assets"] as Assets) ??
      (this.instances["Assets"] = new Assets(this))
    );
  }

  get Referrals(): Referrals {
    return (
      (this.instances["Referrals"] as Referrals) ??
      (this.instances["Referrals"] = new Referrals(this))
    );
  }

  get Docs(): Docs {
    return (
      (this.instances["Docs"] as Docs) ??
      (this.instances["Docs"] = new Docs(this))
    );
  }

  get Main(): Main {
    return (
      (this.instances["Main"] as Main) ??
      (this.instances["Main"] = new Main(this))
    );
  }

  get Tasks(): Tasks {
    return (
      (this.instances["Tasks"] as Tasks) ??
      (this.instances["Tasks"] = new Tasks(this))
    );
  }

  get Channels(): Channels {
    return (
      (this.instances["Channels"] as Channels) ??
      (this.instances["Channels"] = new Channels(this))
    );
  }

  get Tracks(): Tracks {
    return (
      (this.instances["Tracks"] as Tracks) ??
      (this.instances["Tracks"] = new Tracks(this))
    );
  }

  get Performers(): Performers {
    return (
      (this.instances["Performers"] as Performers) ??
      (this.instances["Performers"] = new Performers(this))
    );
  }

  get Notifications(): Notifications {
    return (
      (this.instances["Notifications"] as Notifications) ??
      (this.instances["Notifications"] = new Notifications(this))
    );
  }

  get Albums(): Albums {
    return (
      (this.instances["Albums"] as Albums) ??
      (this.instances["Albums"] = new Albums(this))
    );
  }

  get Applications(): Applications {
    return (
      (this.instances["Applications"] as Applications) ??
      (this.instances["Applications"] = new Applications(this))
    );
  }

  get Storage(): Storage {
    return (
      (this.instances["Storage"] as Storage) ??
      (this.instances["Storage"] = new Storage(this))
    );
  }

  get Companies(): Companies {
    return (
      (this.instances["Companies"] as Companies) ??
      (this.instances["Companies"] = new Companies(this))
    );
  }

  get Reactrino(): Reactrino {
    return (
      (this.instances["Reactrino"] as Reactrino) ??
      (this.instances["Reactrino"] = new Reactrino(this))
    );
  }

  get Genres(): Genres {
    return (
      (this.instances["Genres"] as Genres) ??
      (this.instances["Genres"] = new Genres(this))
    );
  }
}

export { RestAPI };

export type TDateTime = string;

export type TDateTimeZone = string;

export type TIdentifier = string | number;

export interface IChannel {
  id: number;
  extId: string;
  handle: string | null;
  name: string;
  icon: IAsset | null;
  owner?: IUser | null;
  subscribers: number;
  isAvailable: boolean;
  createdAt?: TDateTime;
  checkedAt?: TDateTime;
  url: string;
}

export interface IVideoEmbed {
  id: number;
  tag: string;
  user?: IUser;
  language?: ELanguage;
  comment?: string;
  status?: EReviewStatus;
  rejectReason?: string | null;
  tracks?: ITrack[];
  preview: IAsset | null;
  video?: IAsset | null;
  views?: number;
  videoCount?: number;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
}

export interface IVideoEmbedItem {
  id: number;
  videoEmbed?: IVideoEmbed;
  type: EVideoEmbedItemType;
  duration: number;
  video: IAsset | null;
  audio: IAsset | null;
  extra?: Record<string, any>;
  videoRange: [number, number];
  audioRange: [number, number] | null;
  title: string | null;
  text: string | null;
  scheme: EScheme | null;
}

export interface IAsset {
  id: string;
  name: string;
  mime: string;
  size: number;
  isS3?: boolean;
  createdAt?: TDateTime;
  checkedAt?: TDateTime;
  extra?: Record<string, any>;
  url: string;
  duration?: number | null;
  durationString?: string | null;
  resolution?: [] | null;
  resolutionString?: string | null;
}

export interface ITrack {
  id: number;
  tag: string;
  title: string;
  feat: string | null;
  version: string | null;
  authorLyrics?: string | null;
  authorMusic?: string;
  file?: IAsset;
  sample?: IAsset | null;
  performer: IPerformer;
  album?: IAlbum | null;
  mainGenre?: IGenre | null;
  subGenre?: IGenre | null;
  mood?: EMusicMood;
  lyrics?: string | null;
  isrc?: string | null;
  audioStatus?: EContentIdStatus;
  videoStatus?: EContentIdStatus;
  videoClaim?: IAsset | null;
  language?: ELanguage | null;
  status?: EReviewStatus;
  isPublished?: boolean;
  rejectReason?: string | null;
  scheme?: EScheme | null;
  distributor?: EDistributor | null;
  videoEmbed?: IVideoEmbed | null;
  comment?: string | null;
  duration?: number;
}

export interface IVideoContent {
  id: number;
  user?: IUser;
  media: IAsset;
  category: EVideoContentCategory;
  assignors: string[];
  track: string | null;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  duration: number;
}

export interface ICompany {
  id: number;
  name: string;
  tradingAs: string;
  number?: string;
  registeredIn?: string;
  address?: string;
  director?: string;
  url?: string;
  email?: string;
  bankDetails?: [];
}

export interface ITransaction {
  id: number;
  user?: IUser;
  type: ETransactionType;
  balanceBefore: number;
  amount: number;
  balanceAfter: number;
  comment: string | null;
  createdAt: TDateTime;
  extra?: Record<string, any>;
}

export interface IAlbum {
  id: number;
  title: string;
  cover: IAsset | null;
  performer?: IPerformer;
  tracks?: ITrack[];
  batch?: IBatch | null;
  status?: EReviewStatus;
  rejectReason?: string | null;
  isSingle: boolean;
  tracksCount: number;
  links?: IAlbumLinks;
  upc?: string | null;
}

export interface IPartnerChannel {
  id: number;
  user?: IUser;
  channel?: IChannel;
  status: EPartnerChannelStatus;
  contacts: string;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  isDeleted?: boolean;
  deletedAt?: TDateTime | null;
}

export interface IPerformer {
  id: number;
  user?: IUser;
  nationality?: ECountry | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  stageName: string | null;
  address?: string | null;
  phoneNumber?: string | null;
  passport?: IPassport;
  passportScan?: IAsset | null;
  passportProof?: IAsset | null;
  addressScan?: IAsset | null;
  bankDetails?: IBankDetails;
  status: EReviewStatus;
  rejectReason?: string | null;
  bio?: string | null;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  photos?: IAsset[];
  fullName?: string;
}

export interface IBatch {
  id: number;
  performer?: IPerformer;
  contractBlank?: IAsset | null;
  contractOne?: IAsset | null;
  contractTwo?: IAsset | null;
  albums?: ITrack[];
  trackCost?: number;
  scheme?: EScheme;
  distributor?: EDistributor | null;
  company?: ICompany | null;
  isPaid?: boolean;
  createdAt: TDateTime;
  tag: string;
}

export interface IPerformerApplication {
  id: number;
  name: string;
  email: string;
  contacts: string;
  unpublishedTracks: string | null;
  originalBeats: string | null;
  comment: string | null;
  questions: string | null;
  status: EReviewStatus;
  rejectReason: string | null;
  createdAt: TDateTime;
  updatedAt: TDateTime | null;
  samples: IAsset[];
  source: string | null;
  ip: string | null;
  country: ECountry | null;
  user?: IUser | null;
  service: EService;
  locale: string;
  bio: string | null;
  duplicate: IPerformerApplication | null;
}

export interface INotification {
  id: number;
  user?: IUser;
  icon: IAsset | null;
  message: string;
  isNew: boolean;
  createdAt: TDateTime;
  extra?: Record<string, any>;
}

export interface IVideo {
  id: number;
  extId: string;
  channel?: IChannel | null;
  hasTrack: boolean;
  cover: IAsset | null;
  title: string | null;
  videoEmbed?: IVideoEmbed;
  status: EVideoStatus;
  views: number;
  duration: number;
  createdAt?: TDateTime;
  checkedAt?: TDateTime;
}

export interface ITag {
  id: number;
  title: string;
}

export interface IUser {
  id: number;
  status: EUserStatus;
  service: EService | null;
  email: string;
  role: EUserRole;
  password?: string;
  balance?: number;
  ref?: IUser | null;
  refTag?: string;
  source?: string | null;
  createdAt?: TDateTime;
  accessedAt?: TDateTime;
  locale?: string;
  ip?: string | null;
  country?: ECountry | null;
  contacts?: IContacts;
}

export interface IGenre {
  id: number;
  name: string;
}

export interface IYoutuberApplication {
  id: number;
  email: string;
  contacts: string;
  views: string;
  links: string[];
  comment: string | null;
  status: EReviewStatus;
  rejectReason: string | null;
  createdAt: TDateTime;
  updatedAt: TDateTime | null;
  source: string | null;
  ip: string | null;
  country: ECountry | null;
  user?: IUser | null;
  ref?: IUser | null;
  service: EService;
}

export interface IDoc {
  id: number;
  parent?: IDoc | null;
  tag: string | null;
  title: string;
  content?: string | null;
  position: number;
  isPublished: boolean;
  parentId?: number | null;
}

export interface IViewStat {
  item?: EViewStatItem;
  itemId?: number;
  date?: TDateTime;
  views?: number;
}

export interface IHealth {
  status: "ok" | "warning" | "danger" | "unknown";
  diskUsed: number;
  diskAvailable: number;
  diskTotal: number;
  diskUsage: number;
  memoryUsed: number;
  memoryAvailable: number;
  memoryTotal: number;
  memoryUsage: number;
  cpuCount: number;
  cpuUsage: number;
  loadAvg: number[];
}

export interface IAdminBadges {
  reviewPerformerApplications: number;
  reviewYoutubersApplications: number;
  reviewPerformerProfiles: number;
  reviewVideoEmbedRequests: number;
  reviewAlbums: number;
  unsignedBatches: number;
  review: number;
}

export interface ICommonRejectRequest {
  reason: string;
}

export interface IInitPartialUploadRequest {
  chunkSize: number;
  fileSize: number;
  fileName: string;
  fileType?: string;
  extra?: boolean;
  resize?: number | null;
  lock?: boolean;
}

export interface IPartialUploadStatus {
  id: string;
  fileName?: string;
  fileType?: string;
  fileSize: number;
  chunkSize: number;
  uploaded: number;
  progress: number;
  asset: IAsset | null;
  request?: IInitPartialUploadRequest;
  isReady: boolean;
}

export interface IUploadRequest {
  upload: { name: string; data: string };
}

export interface IPartialChunkUploadRequest {
  id: string;
  chunk: string;
}

export interface ISignBatchContractRequest {
  documentId: string;
}

export interface IGetBatchesRequest {
  preset?: "unsigned" | null;
  performerId?: number | null;
  page?: number;
  limit?: number;
}

export interface IGetTrendingRequest {
  region?: ECountry | null;
}

export interface ITrendingItem {
  id: string;
  title: string;
  image: string;
  url: string;
}

export interface IGetTrendingResponse {
  region: ECountry;
  items: ITrendingItem[];
}

export interface ISetStorageItemRequest {
  key: string;
  value?: any | null;
  ttl?: number | null;
  isSecured?: boolean;
  isGlobal?: boolean;
}

export interface IGetStorageItemRequest {
  key: string;
  isGlobal?: boolean;
}

export interface IDeleteStorageItemRequest {
  key: string;
  isGlobal?: boolean;
}

export interface ICreateAlbumRequest {
  performerId: number;
  title: string;
  coverId?: string | null;
}

export interface IUpdateAlbumRequest {
  title?: string;
  coverId?: string | null;
  upc?: string | null;
}

export interface IUpdateAlbumLinksRequest {
  links: IAlbumLinks;
}

export interface ICreateSingleRequest {
  performerId: number;
  single: IAddTrackRequest;
}

export interface IAlbumLinks {
  appleMusic: string | null;
  spotify: string | null;
  tidal: string | null;
  deezer: string | null;
  soundCloud: string | null;
  yandexMusic: string | null;
  amazon: string | null;
  zvuk: string | null;
  array: string[];
}

export interface IGetAlbumsRequest {
  performerId?: number | null;
  status?: EReviewStatus;
  preset?: "normal" | "upload" | "pending";
  page?: number;
  limit?: number;
}

export interface IGetPartnerChannelsRequest {
  query?: string;
  status?: EPartnerChannelStatus;
  page?: number;
  limit?: number;
}

export interface IReferralRecord {
  id: number;
  email: string;
  status: EUserStatus;
  source: string | null;
  channels: number;
  tracks: number;
  views: number;
  createdAt: TDateTime;
  releaseAt: TDateTime;
  country: ECountry | null;
}

export interface IAddPartnerChannelRequest {
  channelId: string;
  contacts: string;
}

export interface IUpdateVideoEmbedRequest {
  videoId?: string | null;
  previewId?: string | null;
}

export interface IBulkOperationRequest {
  operation: "delete" | "cut";
  ids: number[];
}

export interface IVideoEmbedRequest {
  comment: string;
  language: ELanguage;
}

export interface IGetVideoEmbedsRequest {
  status?: EReviewStatus;
  userId?: number;
  page?: number;
  limit?: number;
}

export interface IAddVideoEmbedItemRequest {
  type: EVideoEmbedItemType;
  audioAssetId?: string | null;
  audioRange?: [number, number] | null;
  videoAssetId?: string | null;
  videoRange?: [number, number] | null;
  scheme?: EScheme | null;
  title?: string | null;
  text?: string | null;
}

export interface IUpdateVideoEmbedItemRequest {
  audioAssetId?: string | null;
  audioRange?: [number, number] | null;
  videoAssetId?: string | null;
  videoRange?: [number, number] | null;
  scheme?: EScheme | null;
  title?: string | null;
  text?: string | null;
}

export interface IGetVideoContentRequest {
  userId?: number;
  query?: string;
  category?: EVideoContentCategory;
  page?: number;
  limit?: number;
}

export interface IAddVideoContentRequest {
  mediaAssetId: string;
  category: EVideoContentCategory;
  assignors: string[];
  track?: string | null;
}

export interface IBulkTracksRequest {
  ids: number[];
}

export interface IGenerateVideoEmbedTextsRequest {
  videoEmbedId: number;
  language: ELanguage;
}

export interface IGenerateVideoEmbedTextsTask {
  videoEmbedId?: number;
  language?: ELanguage;
  advText: string | null;
  reqText: string | null;
  descText: string | null;
  messageClass?: string;
  id?: string;
  status?: ETaskStatus;
  progress?: number | null;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  title?: string | null;
  description?: string | null;
}

export interface IExportTracksCoversTask {
  request?: IBulkTracksRequest;
  zip: IAsset | null;
  messageClass?: string;
  id?: string;
  status?: ETaskStatus;
  progress?: number | null;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  title?: string | null;
  description?: string | null;
}

export interface IGenerateTextTask {
  userId?: number;
  subject: string | null;
  body: string | null;
  request?: IGenerateTextRequest;
  messageClass?: string;
  id?: string;
  status?: ETaskStatus;
  progress?: number | null;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  title?: string | null;
  description?: string | null;
}

export interface ICreateReviewAlbumsArchiveTask {
  zip: IAsset | null;
  messageClass?: string;
  id?: string;
  status?: ETaskStatus;
  progress?: number | null;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  title?: string | null;
  description?: string | null;
}

export interface IUpdateTaskRequest {
  email?: string;
}

export interface IExportTracksAudioTask {
  messageClass?: string;
  request?: IBulkTracksRequest;
  zip?: IAsset | null;
  id?: string;
  status?: ETaskStatus;
  progress?: number | null;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  title?: string | null;
  description?: string | null;
}

export interface IAbstractTask {
  id: string;
  status: ETaskStatus;
  progress: number | null;
  title: string | null;
  description: string | null;
  createdAt: TDateTime;
  updatedAt: TDateTime;
  messageClass?: string;
}

export interface IRenderVideoEmbedTask {
  videoEmbedId?: number;
  preview: IAsset | null;
  result: IAsset | null;
  messageClass?: string;
  id?: string;
  status?: ETaskStatus;
  progress?: number | null;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  title?: string | null;
  description?: string | null;
}

export interface IGenerateTextRequest {
  template: "01" | "02";
  language: ELanguage;
  params: Record<"channelName" | "channelId" | "text" | string, string>;
}

export interface IMakeLyricsAnalysisTask {
  trackId?: number;
  result: string | null;
  messageClass?: string;
  id?: string;
  status?: ETaskStatus;
  progress?: number | null;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  title?: string | null;
  description?: string | null;
}

export interface IChargeUserRequest {
  amount: number;
  type: ETransactionType;
  comment: string;
}

export interface IGetTransactionsHistoryRequest {
  type?: ETransactionType;
  page?: number;
  limit?: number;
}

export interface IDocPlainNode {
  id: number;
  title: string;
  level: number;
  isPublished: boolean;
}

export interface ICreateDocRequest {
  parentId?: number | null;
  position?: number;
  title: string;
  content?: string | null;
  tag?: string | null;
  isPublished?: boolean;
}

export interface ISortDocsRequest {
  docIds: number[];
}

export interface IGetDocsRequest {
  parentId?: number | null;
  onlyPublished?: boolean;
}

export interface IGetPlainTreeRequest {
  parentId?: number | null;
  excludeIds?: number[];
}

export interface IDocNode {
  id: number | null;
  title: string;
  children: IDocNode[];
}

export interface IDocItem {
  id: number;
  title: string;
}

export interface IUpdateDocRequest {
  parentId?: number | null;
  position?: number;
  title?: string;
  content?: string | null;
  tag?: string | null;
  isPublished?: boolean;
}

export interface ISettings {
  openAiCredentials: string[];
  googleApiCredentials: string[];
  aiLyricsAnalysisPrompt?: string;
}

export interface IAddVideoRequest {
  url: string;
  force?: boolean;
}

export interface IGetVideoRequest {
  query?: string | null;
  status?: EVideoStatus | null;
  page?: number;
  limit?: number;
}

export interface IBulkVideoOperationRequest {
  operation: "cut" | "delete";
  videoIds: number[];
}

export interface IGetTracksRequest {
  query?: string;
  batchId?: number;
  performerId?: number;
  albumId?: number;
  hasIsrc?: boolean;
  hasOwner?: boolean;
  isPublished?: boolean;
  isPaid?: boolean;
  hasVideoEmbed?: boolean;
  language?: ELanguage | ELanguage[];
  status?: EReviewStatus;
  audioStatus?: EContentIdStatus;
  videoStatus?: EContentIdStatus;
  sort?: "id" | "views";
  order?: ESortOrder;
  page?: number;
  limit?: number;
}

export interface ICreateBatchRequest {
  performerId: number;
  companyId: number;
  scheme: EScheme;
  distributor?: EDistributor | null;
  trackCost: number;
  albums: number[];
}

export interface IAddTrackRequest {
  albumId?: number | null;
  coverId?: string | null;
  fileId: string;
  title: string;
  stageName?: string | null;
  mainGenreId?: number;
  subGenreId: number;
  mood?: EMusicMood;
  lyrics?: string | null;
  authorLyrics?: string | null;
  authorMusic: string;
  feat?: string | null;
  version?: string | null;
  language?: ELanguage | null;
}

export interface IUpdateMonetizationRequest {
  videoStatus?: EContentIdStatus;
  audioStatus?: EContentIdStatus;
  isrc?: string | null;
  videoClaimId?: string | null;
}

export interface ITrackInfo {
  track: ITrack;
  mp3: IAsset;
  videoClaim: IAsset | null;
  qrCode: IAsset;
}

export interface IUpdateTrackRequest {
  fileId?: string;
  coverId?: string;
  title?: string;
  mainGenreId?: number;
  subGenreId?: number;
  mood?: EMusicMood;
  lyrics?: string | null;
  authorLyrics?: string | null;
  authorMusic?: string;
  feat?: string | null;
  version?: string | null;
  language?: ELanguage | null;
  isrc?: string | null;
  distributor?: EDistributor | null;
  scheme?: EScheme | null;
  comment?: string | null;
}

export interface IBankDetails {
  accountHolder: string;
  bic: string;
  iban: string;
  currency: ECurrency;
  bankName: string;
  bankAddress: string;
  cardNumber: string | null;
  cardHolder: string | null;
  corrName: string | null;
  corrBic: string | null;
  corrIban: string | null;
}

export interface IUpdatePerformerRequest {
  nationality?: ECountry;
  lastName?: string;
  firstName?: string;
  middleName?: string | null;
  stageName?: string;
  address?: string;
  passport?: IPassport;
  passportScanId?: string;
  passportProofId?: string;
  addressScanId?: string;
  bankDetails?: IBankDetails;
  phoneNumber?: string;
  bio?: string;
  photoIds?: string[];
}

export interface ICreatePerformerRequest {
  stageName: string;
}

export interface IGetPerformersListRequest {
  userId?: number | null;
  query?: string | null;
  status?: EReviewStatus | null;
  page?: number;
  limit?: number;
}

export interface IPassport {
  type: string | null;
  series: string | null;
  number: string | null;
  issuedBy: string | null;
  issuedAt: string | null;
}

export interface IUpdateCompanyRequest {
  name: string;
  tradingAs: string;
  number: string;
  registeredIn: string;
  address: string;
  director: string;
  url: string;
  email: string;
  bankDetails: IBankDetails;
}

export interface IPerformerApplicationSubmitRequest {
  name: string;
  email: string;
  contacts: string;
  bio: string;
  questions?: string | null;
  samples: string[];
  unpublishedTracks: string;
  originalBeats: string;
  service?: EService | null;
  source?: string | null;
}

export interface IApproveApplicationRequest {
  email?: string | null;
  password?: string | null;
}

export interface IYoutuberApplicationSubmitRequest {
  email: string;
  contacts: string;
  links: string[];
  views: string;
  service?: EService | null;
  source?: string | null;
  ref?: string | null;
}

export interface IGetApplicationsRequest {
  query?: string;
  status?: EReviewStatus;
  order?: ESortOrder;
  country?: ECountry;
  page?: number;
  limit?: number;
}

export interface IUpdateApplicationRequest {
  comment?: string | null;
}

export interface IGetUsersListRequest {
  query?: string;
  status?: EUserStatus;
  service?: EService;
  role?: EUserRole;
  page?: number;
  limit?: number;
}

export interface IFinishPasswordResetRequest {
  token: string;
}

export interface ILoginRequest {
  email: string;
  password: string;
  ttl?: number;
  role?: EUserRole | null;
  service?: EService | null;
}

export interface IBeginPasswordResetRequest {
  email: string;
}

export interface IRegisterRequest {
  email: string;
  password: string;
  service?: EService | null;
  source?: string | null;
}

export interface IContacts {
  telegram: string | null;
  whatsapp: string | null;
  viber: string | null;
  tel: string | null;
}

export enum EVideoContentCategory {
  Dance = "dance",
  Reaction = "reaction",
}

export enum EVideoStatus {
  Public = "public",
  Unlisted = "unlisted",
  Unavailable = "unavailable",
}

export enum ECurrency {
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  ANG = "ANG",
  AOA = "AOA",
  ARS = "ARS",
  AUD = "AUD",
  AWG = "AWG",
  AZN = "AZN",
  BAM = "BAM",
  BBD = "BBD",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BMD = "BMD",
  BND = "BND",
  BOB = "BOB",
  BRL = "BRL",
  BSD = "BSD",
  BTC = "BTC",
  BTN = "BTN",
  BWP = "BWP",
  BYN = "BYN",
  BZD = "BZD",
  CAD = "CAD",
  CDF = "CDF",
  CHF = "CHF",
  CLF = "CLF",
  CLP = "CLP",
  CNH = "CNH",
  CNY = "CNY",
  COP = "COP",
  CRC = "CRC",
  CUC = "CUC",
  CUP = "CUP",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  EUR = "EUR",
  FJD = "FJD",
  FKP = "FKP",
  GBP = "GBP",
  GEL = "GEL",
  GGP = "GGP",
  GHS = "GHS",
  GIP = "GIP",
  GMD = "GMD",
  GNF = "GNF",
  GTQ = "GTQ",
  GYD = "GYD",
  HKD = "HKD",
  HNL = "HNL",
  HRK = "HRK",
  HTG = "HTG",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  IMP = "IMP",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JEP = "JEP",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KGS = "KGS",
  KHR = "KHR",
  KMF = "KMF",
  KPW = "KPW",
  KRW = "KRW",
  KWD = "KWD",
  KYD = "KYD",
  KZT = "KZT",
  LAK = "LAK",
  LBP = "LBP",
  LKR = "LKR",
  LRD = "LRD",
  LSL = "LSL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MNT = "MNT",
  MOP = "MOP",
  MRU = "MRU",
  MUR = "MUR",
  MVR = "MVR",
  MWK = "MWK",
  MXN = "MXN",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PGK = "PGK",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SBD = "SBD",
  SCR = "SCR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SHP = "SHP",
  SLL = "SLL",
  SOS = "SOS",
  SRD = "SRD",
  SSP = "SSP",
  STD = "STD",
  STN = "STN",
  SVC = "SVC",
  SYP = "SYP",
  SZL = "SZL",
  THB = "THB",
  TJS = "TJS",
  TMT = "TMT",
  TND = "TND",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  USD = "USD",
  UYU = "UYU",
  UZS = "UZS",
  VES = "VES",
  VND = "VND",
  VUV = "VUV",
  WST = "WST",
  XAF = "XAF",
  XAG = "XAG",
  XAU = "XAU",
  XCD = "XCD",
  XDR = "XDR",
  XOF = "XOF",
  XPD = "XPD",
  XPF = "XPF",
  XPT = "XPT",
  YER = "YER",
  ZAR = "ZAR",
  ZMW = "ZMW",
  ZWL = "ZWL",
}

export enum EMusicMood {
  Chill = "chill",
  Uplifting = "uplifting",
  Sad = "sad",
  Mysterious = "mysterious",
  Angry = "angry",
  Creepy = "creepy",
  Melancholic = "melancholic",
  Dreamy = "dreamy",
  Dark = "dark",
  Intense = "intense",
  Upbeat = "upbeat",
  Relaxing = "relaxing",
  Romantic = "romantic",
  Calm = "calm",
  Scary = "scary",
  Happy = "happy",
  Peaceful = "peaceful",
  Energetic = "energetic",
  Epic = "epic",
  Other = "other",
}

export enum ETaskStatus {
  Queued = "queued",
  Processing = "processing",
  Finished = "finished",
  Failed = "failed",
}

export enum EDistributor {
  FreshTunes = "freshtunes",
  Believe = "believe",
  Orchard = "orchard",
  Yoola = "yoola",
  TuneCore = "tunecore",
}

export enum ECountry {
  AU = "au",
  AT = "at",
  AZ = "az",
  AX = "ax",
  AL = "al",
  DZ = "dz",
  VI = "vi",
  AS = "as",
  AI = "ai",
  AO = "ao",
  AD = "ad",
  AQ = "aq",
  AG = "ag",
  AR = "ar",
  AM = "am",
  AW = "aw",
  AF = "af",
  BS = "bs",
  BD = "bd",
  BB = "bb",
  BH = "bh",
  BZ = "bz",
  BY = "by",
  BE = "be",
  BJ = "bj",
  BM = "bm",
  BG = "bg",
  BO = "bo",
  BQ = "bq",
  BA = "ba",
  BW = "bw",
  BR = "br",
  IO = "io",
  VG = "vg",
  BN = "bn",
  BF = "bf",
  BI = "bi",
  BT = "bt",
  VU = "vu",
  VA = "va",
  GB = "gb",
  HU = "hu",
  VE = "ve",
  UM = "um",
  TL = "tl",
  VN = "vn",
  GA = "ga",
  HT = "ht",
  GY = "gy",
  GM = "gm",
  GH = "gh",
  GP = "gp",
  GT = "gt",
  GF = "gf",
  GN = "gn",
  GW = "gw",
  DE = "de",
  GG = "gg",
  GI = "gi",
  HN = "hn",
  HK = "hk",
  GD = "gd",
  GL = "gl",
  GR = "gr",
  GE = "ge",
  GU = "gu",
  DK = "dk",
  JE = "je",
  DJ = "dj",
  DM = "dm",
  DO = "do",
  CD = "cd",
  EU = "eu",
  EG = "eg",
  ZM = "zm",
  EH = "eh",
  ZW = "zw",
  IL = "il",
  IN = "in",
  ID = "id",
  JO = "jo",
  IQ = "iq",
  IR = "ir",
  IE = "ie",
  IS = "is",
  ES = "es",
  IT = "it",
  YE = "ye",
  CV = "cv",
  KZ = "kz",
  KY = "ky",
  KH = "kh",
  CM = "cm",
  CA = "ca",
  QA = "qa",
  KE = "ke",
  CY = "cy",
  KG = "kg",
  KI = "ki",
  TW = "tw",
  KP = "kp",
  CN = "cn",
  CC = "cc",
  CO = "co",
  KM = "km",
  CR = "cr",
  CI = "ci",
  CU = "cu",
  KW = "kw",
  CW = "cw",
  LA = "la",
  LV = "lv",
  LS = "ls",
  LR = "lr",
  LB = "lb",
  LY = "ly",
  LT = "lt",
  LI = "li",
  LU = "lu",
  MU = "mu",
  MR = "mr",
  MG = "mg",
  YT = "yt",
  MO = "mo",
  MK = "mk",
  MW = "mw",
  MY = "my",
  ML = "ml",
  MV = "mv",
  MT = "mt",
  MA = "ma",
  MQ = "mq",
  MH = "mh",
  MX = "mx",
  FM = "fm",
  MZ = "mz",
  MD = "md",
  MC = "mc",
  MN = "mn",
  MS = "ms",
  MM = "mm",
  NA = "na",
  NR = "nr",
  NP = "np",
  NE = "ne",
  NG = "ng",
  NL = "nl",
  NI = "ni",
  NU = "nu",
  NZ = "nz",
  NC = "nc",
  NO = "no",
  AE = "ae",
  OM = "om",
  BV = "bv",
  IM = "im",
  CK = "ck",
  NF = "nf",
  CX = "cx",
  PN = "pn",
  SH = "sh",
  PK = "pk",
  PW = "pw",
  PS = "ps",
  PA = "pa",
  PG = "pg",
  PY = "py",
  PE = "pe",
  PL = "pl",
  PT = "pt",
  PR = "pr",
  CG = "cg",
  KR = "kr",
  RE = "re",
  RU = "ru",
  RW = "rw",
  RO = "ro",
  SV = "sv",
  WS = "ws",
  SM = "sm",
  ST = "st",
  SA = "sa",
  SZ = "sz",
  MP = "mp",
  SC = "sc",
  BL = "bl",
  MF = "mf",
  PM = "pm",
  SN = "sn",
  VC = "vc",
  KN = "kn",
  LC = "lc",
  RS = "rs",
  SG = "sg",
  SX = "sx",
  SY = "sy",
  SK = "sk",
  SI = "si",
  SB = "sb",
  SO = "so",
  SD = "sd",
  SR = "sr",
  US = "us",
  SL = "sl",
  TJ = "tj",
  TH = "th",
  TZ = "tz",
  TC = "tc",
  TG = "tg",
  TK = "tk",
  TO = "to",
  TT = "tt",
  TV = "tv",
  TN = "tn",
  TM = "tm",
  TR = "tr",
  UG = "ug",
  UZ = "uz",
  UA = "ua",
  WF = "wf",
  UY = "uy",
  FO = "fo",
  FJ = "fj",
  PH = "ph",
  FI = "fi",
  FK = "fk",
  FR = "fr",
  PF = "pf",
  TF = "tf",
  HM = "hm",
  HR = "hr",
  CF = "cf",
  TD = "td",
  ME = "me",
  CZ = "cz",
  CL = "cl",
  CH = "ch",
  SE = "se",
  SJ = "sj",
  LK = "lk",
  EC = "ec",
  GQ = "gq",
  ER = "er",
  EE = "ee",
  ET = "et",
  ZA = "za",
  GS = "gs",
  SS = "ss",
  JM = "jm",
  JP = "jp",
}

export enum EScheme {
  White = "white",
  Gray = "gray",
}

export enum EService {
  Zoundo = "zoundo",
  Tubyx = "tubyx",
  TubePays = "tubepays",
  Axtune = "axtune",
  Reactrino = "reactrino",
}

export enum EUserRole {
  Admin = "admin",
  ContentManager = "content_manager",
  AdsManager = "ads_manager",
  Performer = "performer",
  Youtuber = "youtuber",
  Partner = "partner",
}

export enum ETransactionType {
  Payin = "payin",
  Payout = "payout",
  Referral = "referral",
  Spending = "spending",
  Selling = "selling",
  Promo = "promo",
  Other = "other",
}

export enum ELanguage {
  Abkhazian = "ab",
  Afar = "aa",
  Afrikaans = "af",
  Akan = "ak",
  Albanian = "sq",
  Amharic = "am",
  Arabic = "ar",
  Aragonese = "an",
  Armenian = "hy",
  Assamese = "as",
  Avaric = "av",
  Avestan = "ae",
  Aymara = "ay",
  Azerbaijani = "az",
  Bambara = "bm",
  Bashkir = "ba",
  Basque = "eu",
  Belarusian = "be",
  Bengali = "bn",
  Bislama = "bi",
  Bosnian = "bs",
  Breton = "br",
  Bulgarian = "bg",
  Burmese = "my",
  Catalan = "ca",
  Chamorro = "ch",
  Chechen = "ce",
  Chichewa = "ny",
  Chinese = "zh",
  ChurchSlavonic = "cu",
  Chuvash = "cv",
  Cornish = "kw",
  Corsican = "co",
  Cree = "cr",
  Croatian = "hr",
  Czech = "cs",
  Danish = "da",
  Divehi = "dv",
  Dutch = "nl",
  Dzongkha = "dz",
  English = "en",
  Esperanto = "eo",
  Estonian = "et",
  Ewe = "ee",
  Faroese = "fo",
  Fijian = "fj",
  Finnish = "fi",
  French = "fr",
  WesternFrisian = "fy",
  Fulah = "ff",
  Gaelic = "gd",
  Galician = "gl",
  Ganda = "lg",
  Georgian = "ka",
  German = "de",
  Greek = "el",
  Kalaallisut = "kl",
  Guarani = "gn",
  Gujarati = "gu",
  Haitian = "ht",
  Hausa = "ha",
  Hebrew = "he",
  Herero = "hz",
  Hindi = "hi",
  HiriMotu = "ho",
  Hungarian = "hu",
  Icelandic = "is",
  Ido = "io",
  Igbo = "ig",
  Indonesian = "id",
  Interlingua = "ia",
  Interlingue = "ie",
  Inuktitut = "iu",
  Inupiaq = "ik",
  Irish = "ga",
  Italian = "it",
  Japanese = "ja",
  Javanese = "jv",
  Kannada = "kn",
  Kanuri = "kr",
  Kashmiri = "ks",
  Kazakh = "kk",
  CentralKhmer = "km",
  Kikuyu = "ki",
  Kinyarwanda = "rw",
  Kirghiz = "ky",
  Komi = "kv",
  Kongo = "kg",
  Korean = "ko",
  Kuanyama = "kj",
  Kurdish = "ku",
  Lao = "lo",
  Latin = "la",
  Latvian = "lv",
  Limburgan = "li",
  Lingala = "ln",
  Lithuanian = "lt",
  LubaKatanga = "lu",
  Luxembourgish = "lb",
  Macedonian = "mk",
  Malagasy = "mg",
  Malay = "ms",
  Malayalam = "ml",
  Maltese = "mt",
  Manx = "gv",
  Maori = "mi",
  Marathi = "mr",
  Marshallese = "mh",
  Mongolian = "mn",
  Nauru = "na",
  Navajo = "nv",
  NorthNdebele = "nd",
  SouthNdebele = "nr",
  Ndonga = "ng",
  Nepali = "ne",
  Norwegian = "no",
  NorwegianBokmal = "nb",
  NorwegianNynorsk = "nn",
  SichuanYi = "ii",
  Occitan = "oc",
  Ojibwa = "oj",
  Oriya = "or",
  Oromo = "om",
  Ossetian = "os",
  Pali = "pi",
  Pashto = "ps",
  Persian = "fa",
  Polish = "pl",
  Portuguese = "pt",
  Punjabi = "pa",
  Quechua = "qu",
  Romanian = "ro",
  Romansh = "rm",
  Rundi = "rn",
  Russian = "ru",
  NorthernSami = "se",
  Samoan = "sm",
  Sango = "sg",
  Sanskrit = "sa",
  Sardinian = "sc",
  Serbian = "sr",
  Shona = "sn",
  Sindhi = "sd",
  Sinhala = "si",
  Slovak = "sk",
  Slovenian = "sl",
  Somali = "so",
  SouthernSotho = "st",
  Spanish = "es",
  Sundanese = "su",
  Swahili = "sw",
  Swati = "ss",
  Swedish = "sv",
  Tagalog = "tl",
  Tahitian = "ty",
  Tajik = "tg",
  Tamil = "ta",
  Tatar = "tt",
  Telugu = "te",
  Thai = "th",
  Tibetan = "bo",
  Tigrinya = "ti",
  Tonga = "to",
  Tsonga = "ts",
  Tswana = "tn",
  Turkish = "tr",
  Turkmen = "tk",
  Twi = "tw",
  Uighur = "ug",
  Ukrainian = "uk",
  Urdu = "ur",
  Uzbek = "uz",
  Venda = "ve",
  Vietnamese = "vi",
  Volapuk = "vo",
  Walloon = "wa",
  Welsh = "cy",
  Wolof = "wo",
  Xhosa = "xh",
  Yiddish = "yi",
  Yoruba = "yo",
  Zhuang = "za",
  Zulu = "zu",
}

export enum EVideoEmbedItemType {
  Video = "video",
  VideoQR = "video-qr",
  Text = "text",
}

export enum EContentIdStatus {
  None = "none",
  Pending = "pending",
  Active = "active",
  Excluded = "excluded",
}

export enum EViewStatItem {
  Video = "video",
  VideoEmbed = "ve",
}

export enum EPartnerChannelStatus {
  Message1 = "msg1",
  Reply = "reply",
  Message2 = "msg2",
  Registration = "reg",
}

export enum EUserStatus {
  Review = "review",
  Active = "active",
  Reject = "reject",
  Suspend = "suspend",
  Block = "block",
}

export enum EReviewStatus {
  Draft = "draft",
  Review = "review",
  Approve = "approve",
  Reject = "reject",
}

export enum ESortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export interface IPagedData<T> {
  page: number;
  limit: number;
  count: number | null;
  pages: number | null;
  data: T[];
}

export enum EFieldGroup {
  ChannelOwner = "channel:owner",
  ChannelDate = "channel:date",
  VideoEmbedUser = "video-embed:user",
  VideoEmbedLanguage = "video-embed:language",
  VideoEmbedComment = "video-embed:comment",
  VideoEmbedStatus = "video-embed:status",
  VideoEmbedTracks = "video-embed:tracks",
  VideoEmbedVideo = "video-embed:video",
  VideoEmbedStats = "video-embed:stats",
  AssetDuration = "asset:duration",
  AssetFull = "asset:full",
  AssetResolution = "asset:resolution",
  AssetS3 = "asset:s3",
  TrackDuration = "track:duration",
  TrackEdit = "track:edit",
  TrackFile = "track:file",
  TrackSample = "track:sample",
  TrackPerformer = "track:performer",
  TrackAlbum = "track:album",
  TrackGenre = "track:genre",
  TrackMood = "track:mood",
  TrackLyrics = "track:lyrics",
  TrackIsrc = "track:isrc",
  TrackStatus = "track:status",
  TrackVideoClaim = "track:video-claim",
  TrackLanguage = "track:language",
  TrackScheme = "track:scheme",
  TrackDistributor = "track:distributor",
  TrackComment = "track:comment",
  VideoContentUser = "video-content:user",
  CompanyDetails = "company:details",
  TransactionUser = "transaction:user",
  TransactionFull = "transaction:full",
  AlbumPerformer = "album:performer",
  AlbumTracks = "album:tracks",
  AlbumBatch = "album:batch",
  AlbumStatus = "album:status",
  AlbumLinks = "album:links",
  AlbumUpc = "album:upc",
  PartnerChannelUser = "partner-channel:user",
  PartnerChannelChannel = "partner-channel:channel",
  PerformerUser = "performer:user",
  PerformerEdit = "performer:edit",
  PerformerStatus = "performer:status",
  PerformerBio = "performer:bio",
  PerformerPhotos = "performer:photos",
  BatchPerformer = "batch:performer",
  BatchContract = "batch:contract",
  BatchAlbums = "batch:albums",
  BatchCost = "batch:cost",
  BatchScheme = "batch:scheme",
  BatchDistributor = "batch:distributor",
  BatchCompany = "batch:company",
  ApplicationUser = "application:user",
  VideoChannel = "video:channel",
  VideoVideoEmbed = "video:video-embed",
  UserBalance = "user:balance",
  UserRef = "user:ref",
  UserSource = "user:source",
  UserDate = "user:date",
  UserLocale = "user:locale",
  UserIp = "user:ip",
  UserCountry = "user:country",
  UserContacts = "user:contacts",
  ApplicationRef = "application:ref",
  DocParentId = "doc:parent-id",
  DocParent = "doc:parent",
  DocContent = "doc:content",
}

class Batches {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getBatchesList = (
    request: IGetBatchesRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IBatch>> => this.api.get(`/batches`, request, fields);

  getBatch = (batch: TIdentifier, fields?: EFieldGroup[]): Promise<IBatch> =>
    this.api.get(`/batches/${batch}`, {}, fields);

  signOneBatch = (
    batch: TIdentifier,
    request: ISignBatchContractRequest,
    fields?: EFieldGroup[]
  ): Promise<IBatch> =>
    this.api.post(`/batches/${batch}/sign/one`, request, fields);

  signTwoBatch = (
    batch: TIdentifier,
    request: ISignBatchContractRequest,
    fields?: EFieldGroup[]
  ): Promise<IBatch> =>
    this.api.post(`/batches/${batch}/sign/two`, request, fields);

  getReadyPerformers = (fields?: EFieldGroup[]): Promise<IPerformer[]> =>
    this.api.get(`/batches/performers/ready`, {}, fields);

  getApprovedAlbums = (
    performer: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IAlbum[]> =>
    this.api.get(`/batches/performers/${performer}/ready-albums`, {}, fields);

  createBatch = (
    request: ICreateBatchRequest,
    fields?: EFieldGroup[]
  ): Promise<IBatch> => this.api.post(`/batches`, request, fields);

  generateContracts = (
    batch: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IBatch> =>
    this.api.post(`/batches/${batch}/contracts`, {}, fields);
}

class Badges {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getAdminBadges = (fields?: EFieldGroup[]): Promise<IAdminBadges> =>
    this.api.get(`/badges/admin`, {}, fields);
}

class Billing {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getTrasnactionsHistory = (
    request: IGetTransactionsHistoryRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<ITransaction>> =>
    this.api.get(`/billing/transactions`, request, fields);

  chargeUser = (
    user: TIdentifier,
    request: IChargeUserRequest,
    fields?: EFieldGroup[]
  ): Promise<ITransaction> =>
    this.api.post(`/billing/users/${user}/charge`, request, fields);
}

class VideoEmbeds {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getList = (
    request: IGetVideoEmbedsRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IVideoEmbed>> =>
    this.api.get(`/video-embed`, request, fields);

  bulkOperation = (
    request: IBulkOperationRequest,
    fields?: EFieldGroup[]
  ): Promise<boolean> => this.api.post(`/video-embed/bulk`, request, fields);

  requestVideoEmbed = (
    request: IVideoEmbedRequest,
    fields?: EFieldGroup[]
  ): Promise<IVideoEmbed> => this.api.post(`/video-embed`, request, fields);

  approveVideoEmbed = (
    ve: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IVideoEmbed> =>
    this.api.patch(`/video-embed/${ve}/approve`, {}, fields);

  rejectVideoEmbed = (
    ve: TIdentifier,
    request: ICommonRejectRequest,
    fields?: EFieldGroup[]
  ): Promise<IVideoEmbed> =>
    this.api.patch(`/video-embed/${ve}/reject`, request, fields);

  getVideoEmbed = (
    ve: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IVideoEmbed> => this.api.get(`/video-embed/${ve}`, {}, fields);

  updateVideoEmbed = (
    ve: TIdentifier,
    request: IUpdateVideoEmbedRequest,
    fields?: EFieldGroup[]
  ): Promise<IVideoEmbed> =>
    this.api.patch(`/video-embed/${ve}`, request, fields);

  addTrackToVideoEmbed = (
    ve: TIdentifier,
    track: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IVideoEmbed> =>
    this.api.post(`/video-embed/${ve}/tracks/${track}`, {}, fields);

  deleteTrackFromVideoEmbed = (
    ve: TIdentifier,
    track: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IVideoEmbed> =>
    this.api.delete(`/video-embed/${ve}/tracks/${track}`, {}, fields);

  getVideoEmbedItems = (
    ve: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IVideoEmbedItem[]> =>
    this.api.get(`/video-embed/${ve}/items`, {}, fields);

  addVideoEmbedItem = (
    ve: TIdentifier,
    request: IAddVideoEmbedItemRequest,
    fields?: EFieldGroup[]
  ): Promise<IVideoEmbedItem> =>
    this.api.post(`/video-embed/${ve}/items`, request, fields);

  getYoutubeVideos = (
    ve: TIdentifier,
    request: IGetVideoRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IVideo>> =>
    this.api.get(`/video-embed/${ve}/videos`, request, fields);

  addYoutubeVideo = (
    ve: TIdentifier,
    request: IAddVideoRequest,
    fields?: EFieldGroup[]
  ): Promise<IVideo> =>
    this.api.post(`/video-embed/${ve}/videos`, request, fields);

  deleteYoutubeVideo = (
    video: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> =>
    this.api.delete(`/video-embed/videos/${video}`, {}, fields);

  cutYoutubeVideo = (
    video: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> =>
    this.api.post(`/video-embed/videos/${video}/cut`, {}, fields);

  updateVideoEmbedItem = (
    item: TIdentifier,
    request: IUpdateVideoEmbedItemRequest,
    fields?: EFieldGroup[]
  ): Promise<IVideoEmbedItem> =>
    this.api.patch(`/video-embed/items/${item}`, request, fields);

  deleteVideoEmbedItem = (
    item: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> =>
    this.api.delete(`/video-embed/items/${item}`, {}, fields);

  copyVideoEmbedItem = (
    item: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IVideoEmbedItem> =>
    this.api.post(`/video-embed/items/${item}/copy`, {}, fields);

  deleteVideoEmbed = (
    ve: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IVideoEmbed> => this.api.delete(`/video-embed/${ve}`, {}, fields);
}

class Melomania {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getTrack = (tag: TIdentifier, fields?: EFieldGroup[]): Promise<ITrack[]> =>
    this.api.get(`/melomania/tag/${tag}`, {}, fields);
}

class Users {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getUsersList = (
    request: IGetUsersListRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IUser>> => this.api.get(`/users`, request, fields);

  loginAs = (
    user: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<{ token: string; user: IUser }> =>
    this.api.post(`/users/${user}/auth`, {}, fields);

  login = (
    request: ILoginRequest,
    fields?: EFieldGroup[]
  ): Promise<{ token: string; user: IUser }> =>
    this.api.post(`/users/login`, request, fields);

  register = (
    request: IRegisterRequest,
    fields?: EFieldGroup[]
  ): Promise<{ token: string; user: IUser }> =>
    this.api.post(`/users/register`, request, fields);

  beginPasswordReset = (
    request: IBeginPasswordResetRequest,
    fields?: EFieldGroup[]
  ): Promise<true> => this.api.post(`/users/password`, request, fields);

  finishPasswordReset = (
    request: IFinishPasswordResetRequest,
    fields?: EFieldGroup[]
  ): Promise<true> => this.api.post(`/users/password/`, request, fields);

  getMe = (fields?: EFieldGroup[]): Promise<IUser> =>
    this.api.get(`/users/me`, {}, fields);

  getUserById = (user: TIdentifier, fields?: EFieldGroup[]): Promise<IUser> =>
    this.api.get(`/users/${user}`, {}, fields);
}

class VideoContent {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getList = (
    request: IGetVideoContentRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IVideoContent>> =>
    this.api.get(`/video-content`, request, fields);

  getAssignors = (fields?: EFieldGroup[]): Promise<string[]> =>
    this.api.get(`/video-content/assignors`, {}, fields);

  addItem = (
    request: IAddVideoContentRequest,
    fields?: EFieldGroup[]
  ): Promise<IVideoContent> => this.api.post(`/video-content`, request, fields);

  getItem = (vc: TIdentifier, fields?: EFieldGroup[]): Promise<IVideoContent> =>
    this.api.get(`/video-content/${vc}`, {}, fields);

  deleteItem = (vc: TIdentifier, fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.delete(`/video-content/${vc}`, {}, fields);
}

class Assets {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getAsset = (asset: TIdentifier, fields?: EFieldGroup[]): Promise<IAsset> =>
    this.api.get(`/assets/${asset}`, {}, fields);

  uploadJson = (
    request: IUploadRequest,
    fields?: EFieldGroup[]
  ): Promise<IAsset> => this.api.post(`/assets/upload/base64`, request, fields);

  uploadForm = (form: FormData): Promise<IAsset> =>
    this.api.post(`/assets/upload/form`, form);

  beginPartial = (
    request: IInitPartialUploadRequest,
    fields?: EFieldGroup[]
  ): Promise<IPartialUploadStatus> =>
    this.api.post(`/assets/upload/partial`, request, fields);

  chunkPartial = (
    request: IPartialChunkUploadRequest,
    fields?: EFieldGroup[]
  ): Promise<IPartialUploadStatus> =>
    this.api.patch(`/assets/upload/partial`, request, fields);
}

class Referrals {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getList = (fields?: EFieldGroup[]): Promise<IReferralRecord[]> =>
    this.api.get(`/referrals`, {}, fields);

  getChannels = (
    request: IGetPartnerChannelsRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IPartnerChannel>> =>
    this.api.get(`/referrals/channels`, request, fields);

  addChannel = (
    request: IAddPartnerChannelRequest,
    fields?: EFieldGroup[]
  ): Promise<IPartnerChannel> =>
    this.api.post(`/referrals/channels`, request, fields);

  lookupPartnerChannel = (
    id: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> =>
    this.api.get(`/referrals/channels/lookup/${id}`, {}, fields);

  setChannelStatus = (
    pc: TIdentifier,
    status: EPartnerChannelStatus,
    fields?: EFieldGroup[]
  ): Promise<IPartnerChannel> =>
    this.api.patch(`/referrals/channels/${pc}/status/${status}`, {}, fields);

  deleteChannel = (pc: TIdentifier, fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.delete(`/referrals/channels/${pc}`, {}, fields);
}

class Docs {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  index = (request: IGetDocsRequest, fields?: EFieldGroup[]): Promise<IDoc[]> =>
    this.api.get(`/docs`, request, fields);

  getPath = (doc: TIdentifier, fields?: EFieldGroup[]): Promise<IDocItem[]> =>
    this.api.get(`/docs/${doc}/path`, {}, fields);

  getPlainTree = (
    request: IGetPlainTreeRequest,
    fields?: EFieldGroup[]
  ): Promise<IDocPlainNode[]> =>
    this.api.get(`/docs/plain-tree`, request, fields);

  create = (
    request: ICreateDocRequest,
    fields?: EFieldGroup[]
  ): Promise<IDoc> => this.api.post(`/docs`, request, fields);

  sort = (request: ISortDocsRequest, fields?: EFieldGroup[]): Promise<IDoc[]> =>
    this.api.patch(`/docs/sort`, request, fields);

  update = (
    doc: TIdentifier,
    request: IUpdateDocRequest,
    fields?: EFieldGroup[]
  ): Promise<IDoc> => this.api.patch(`/docs/${doc}`, request, fields);

  getByTag = (tag: TIdentifier, fields?: EFieldGroup[]): Promise<IDoc> =>
    this.api.get(`/docs/tag/${tag}`, {}, fields);

  getById = (doc: TIdentifier, fields?: EFieldGroup[]): Promise<IDoc> =>
    this.api.get(`/docs/${doc}`, {}, fields);

  delete = (doc: TIdentifier, fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.delete(`/docs/${doc}`, {}, fields);
}

class Main {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  checkVersion = (
    item: TIdentifier,
    version: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<{ version: string; upgrade: boolean }> =>
    this.api.get(`/version/${item}/${version}`, {}, fields);

  getServerHealth = (fields?: EFieldGroup[]): Promise<IHealth> =>
    this.api.get(`/health`, {}, fields);

  getAlbumsFeed = (fields?: EFieldGroup[]): Promise<IAlbum[]> =>
    this.api.post(`/albums-feed`, {}, fields);
}

class Tasks {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getTask = <T>(id: TIdentifier, fields?: EFieldGroup[]): Promise<T> =>
    this.api.get(`/tasks/${id}`, {}, fields);

  generateText = (
    request: IGenerateTextRequest,
    fields?: EFieldGroup[]
  ): Promise<IGenerateTextTask> =>
    this.api.post(`/tasks/text`, request, fields);

  generateVideoEmbedTexts = (
    request: IGenerateVideoEmbedTextsRequest,
    fields?: EFieldGroup[]
  ): Promise<IGenerateVideoEmbedTextsTask> =>
    this.api.post(`/tasks/video-embed-texts`, request, fields);

  createReviewAlbumsArchive = (
    fields?: EFieldGroup[]
  ): Promise<ICreateReviewAlbumsArchiveTask> =>
    this.api.post(`/tasks/create-review-albums-archive`, {}, fields);

  makeLyricsAnalysis = (
    track: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IMakeLyricsAnalysisTask> =>
    this.api.post(`/tasks/make-lyrics-analysis/${track}`, {}, fields);

  exportTracksCovers = (
    request: IBulkTracksRequest,
    fields?: EFieldGroup[]
  ): Promise<IExportTracksCoversTask> =>
    this.api.post(`/tasks/export-tracks-covers`, request, fields);

  exportTracksAudio = (
    request: IBulkTracksRequest,
    fields?: EFieldGroup[]
  ): Promise<IExportTracksAudioTask> =>
    this.api.post(`/tasks/export-tracks-audio`, request, fields);

  renderVideoEmbed = (
    ve: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IRenderVideoEmbedTask> =>
    this.api.post(`/tasks/render-video-embed/${ve}`, {}, fields);

  updateTask = <T>(
    id: TIdentifier,
    request: IUpdateTaskRequest,
    fields?: EFieldGroup[]
  ): Promise<T> => this.api.patch(`/tasks/${id}`, request, fields);
}

class Channels {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  lookupChannel = (
    query: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> => this.api.get(`/channels/lookup/${query}`, {}, fields);
}

class Tracks {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getTracksList = (
    request: IGetTracksRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<ITrack>> => this.api.get(`/tracks`, request, fields);

  getRemainingVideoTracks = (fields?: EFieldGroup[]): Promise<ITrack[]> =>
    this.api.get(`/tracks/remaining-video`, {}, fields);

  addTrack = (
    request: IAddTrackRequest,
    fields?: EFieldGroup[]
  ): Promise<ITrack> => this.api.post(`/tracks`, request, fields);

  updateTrack = (
    track: TIdentifier,
    request: IUpdateTrackRequest,
    fields?: EFieldGroup[]
  ): Promise<ITrack> => this.api.patch(`/tracks/${track}`, request, fields);

  updateTrackMonetization = (
    track: TIdentifier,
    request: IUpdateMonetizationRequest,
    fields?: EFieldGroup[]
  ): Promise<ITrack> =>
    this.api.patch(`/tracks/${track}/monetization`, request, fields);

  getLastPerformerTrack = (
    performer: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<ITrack | null> =>
    this.api.get(`/tracks/last/${performer}`, {}, fields);

  getTrack = (track: TIdentifier, fields?: EFieldGroup[]): Promise<ITrack> =>
    this.api.get(`/tracks/${track}`, {}, fields);

  getTrackInfo = (
    track: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<ITrackInfo> => this.api.get(`/tracks/${track}/info`, {}, fields);

  deleteTrack = (track: TIdentifier, fields?: EFieldGroup[]): Promise<true> =>
    this.api.delete(`/tracks/${track}`, {}, fields);

  adminDraftTrack = (
    track: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<true> =>
    this.api.patch(`/tracks/${track}/admin-draft`, {}, fields);

  adminReviewTrack = (
    track: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<true> =>
    this.api.patch(`/tracks/${track}/admin-review`, {}, fields);

  draftTrack = (track: TIdentifier, fields?: EFieldGroup[]): Promise<true> =>
    this.api.patch(`/tracks/${track}/draft`, {}, fields);

  rejectTrack = (
    track: TIdentifier,
    request: ICommonRejectRequest,
    fields?: EFieldGroup[]
  ): Promise<true> =>
    this.api.patch(`/tracks/${track}/reject`, request, fields);

  approveTrack = (track: TIdentifier, fields?: EFieldGroup[]): Promise<true> =>
    this.api.patch(`/tracks/${track}/approve`, {}, fields);
}

class Performers {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getList = (
    request: IGetPerformersListRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IPerformer>> =>
    this.api.get(`/performers`, request, fields);

  createPerformer = (
    request: ICreatePerformerRequest,
    fields?: EFieldGroup[]
  ): Promise<IPerformer> => this.api.post(`/performers`, request, fields);

  getPerformer = (
    performer: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IPerformer> =>
    this.api.get(`/performers/${performer}`, {}, fields);

  updatePerformer = (
    performer: TIdentifier,
    request: IUpdatePerformerRequest,
    fields?: EFieldGroup[]
  ): Promise<IPerformer> =>
    this.api.patch(`/performers/${performer}`, request, fields);

  reviewPerformer = (
    performer: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IPerformer> =>
    this.api.post(`/performers/${performer}/review`, {}, fields);

  draftPerformer = (
    performer: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IPerformer> =>
    this.api.post(`/performers/${performer}/draft`, {}, fields);

  approvePerformer = (
    performer: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IPerformer> =>
    this.api.post(`/performers/${performer}/approve`, {}, fields);

  rejectPerformer = (
    performer: TIdentifier,
    request: ICommonRejectRequest,
    fields?: EFieldGroup[]
  ): Promise<IPerformer> =>
    this.api.post(`/performers/${performer}/reject`, request, fields);

  deletePerformer = (
    performer: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> =>
    this.api.delete(`/performers/${performer}`, {}, fields);
}

class Notifications {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getList = (fields?: EFieldGroup[]): Promise<INotification[]> =>
    this.api.get(`/notifications`, {}, fields);

  read = (
    notification: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<INotification> =>
    this.api.patch(`/notifications/${notification}`, {}, fields);

  readAll = (fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.patch(`/notifications`, {}, fields);

  delete = (
    notification: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> =>
    this.api.delete(`/notifications/${notification}`, {}, fields);

  deleteAll = (fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.delete(`/notifications`, {}, fields);
}

class Albums {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getAlbums = (
    request: IGetAlbumsRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IAlbum>> => this.api.get(`/albums`, request, fields);

  createAlbum = (
    request: ICreateAlbumRequest,
    fields?: EFieldGroup[]
  ): Promise<IAlbum> => this.api.post(`/albums`, request, fields);

  createSingle = (
    request: ICreateSingleRequest,
    fields?: EFieldGroup[]
  ): Promise<IAlbum> => this.api.post(`/albums/single`, request, fields);

  getAlbum = (album: TIdentifier, fields?: EFieldGroup[]): Promise<IAlbum> =>
    this.api.get(`/albums/${album}`, {}, fields);

  lockAlbum = (album: TIdentifier, fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.patch(`/albums/${album}/lock`, {}, fields);

  unlockAlbum = (
    album: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> => this.api.patch(`/albums/${album}/unlock`, {}, fields);

  updateAlbum = (
    album: TIdentifier,
    request: IUpdateAlbumRequest,
    fields?: EFieldGroup[]
  ): Promise<IAlbum> => this.api.patch(`/albums/${album}`, request, fields);

  updateAlbumLinks = (
    album: TIdentifier,
    request: IUpdateAlbumLinksRequest,
    fields?: EFieldGroup[]
  ): Promise<IAlbumLinks> =>
    this.api.patch(`/albums/${album}/links`, request, fields);

  draftAlbum = (album: TIdentifier, fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.post(`/albums/${album}/draft`, {}, fields);

  reviewAlbum = (
    album: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> => this.api.post(`/albums/${album}/review`, {}, fields);

  approveAlbum = (
    album: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> => this.api.post(`/albums/${album}/approve`, {}, fields);

  rejectAlbum = (
    album: TIdentifier,
    request: ICommonRejectRequest,
    fields?: EFieldGroup[]
  ): Promise<boolean> =>
    this.api.post(`/albums/${album}/reject`, request, fields);

  deleteAlbum = (
    album: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> => this.api.delete(`/albums/${album}`, {}, fields);
}

class Applications {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  submitPerformerApplication = (
    request: IPerformerApplicationSubmitRequest,
    fields?: EFieldGroup[]
  ): Promise<number> =>
    this.api.post(`/applications/performer`, request, fields);

  getPerformerApplications = (
    request: IGetApplicationsRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IPerformerApplication>> =>
    this.api.get(`/applications/performer`, request, fields);

  rejectPerformerApplication = (
    application: TIdentifier,
    request: ICommonRejectRequest,
    fields?: EFieldGroup[]
  ): Promise<IPerformerApplication> =>
    this.api.post(
      `/applications/performer/${application}/reject`,
      request,
      fields
    );

  updatePerformerApplication = (
    application: TIdentifier,
    request: IUpdateApplicationRequest,
    fields?: EFieldGroup[]
  ): Promise<IPerformerApplication> =>
    this.api.patch(`/applications/performer/${application}`, request, fields);

  approvePerformerApplication = (
    application: TIdentifier,
    request: IApproveApplicationRequest,
    fields?: EFieldGroup[]
  ): Promise<IPerformerApplication> =>
    this.api.post(
      `/applications/performer/${application}/approve`,
      request,
      fields
    );

  submitYoutuberApplication = (
    request: IYoutuberApplicationSubmitRequest,
    fields?: EFieldGroup[]
  ): Promise<number> =>
    this.api.post(`/applications/youtuber`, request, fields);

  getYoutuberApplications = (
    request: IGetApplicationsRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IYoutuberApplication>> =>
    this.api.get(`/applications/youtuber`, request, fields);

  rejectYoutuberApplication = (
    application: TIdentifier,
    request: ICommonRejectRequest,
    fields?: EFieldGroup[]
  ): Promise<IYoutuberApplication> =>
    this.api.post(
      `/applications/youtuber/${application}/reject`,
      request,
      fields
    );

  approveYoutuberApplication = (
    application: TIdentifier,
    request: IApproveApplicationRequest,
    fields?: EFieldGroup[]
  ): Promise<IYoutuberApplication> =>
    this.api.post(
      `/applications/youtuber/${application}/approve`,
      request,
      fields
    );
}

class Storage {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getItem = <T>(
    request: IGetStorageItemRequest,
    fields?: EFieldGroup[]
  ): Promise<T | null> => this.api.get(`/storage`, request, fields);

  setItem = (
    request: ISetStorageItemRequest,
    fields?: EFieldGroup[]
  ): Promise<boolean> => this.api.put(`/storage`, request, fields);

  deleteItem = (
    request: IDeleteStorageItemRequest,
    fields?: EFieldGroup[]
  ): Promise<boolean> => this.api.delete(`/storage`, request, fields);
}

class Companies {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getList = (fields?: EFieldGroup[]): Promise<ICompany[]> =>
    this.api.get(`/companies`, {}, fields);

  createCompany = (
    request: IUpdateCompanyRequest,
    fields?: EFieldGroup[]
  ): Promise<ICompany> => this.api.post(`/companies`, request, fields);

  updateCompany = (
    company: TIdentifier,
    request: IUpdateCompanyRequest,
    fields?: EFieldGroup[]
  ): Promise<ICompany> =>
    this.api.patch(`/companies/${company}`, request, fields);

  getCompany = (
    company: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<ICompany> => this.api.get(`/companies/${company}`, {}, fields);

  deleteCompany = (
    company: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> => this.api.delete(`/companies/${company}`, {}, fields);
}

class Reactrino {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getTrending = (
    request: IGetTrendingRequest,
    fields?: EFieldGroup[]
  ): Promise<IGetTrendingResponse> =>
    this.api.get(`/reactrino/trending`, request, fields);
}

class Genres {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getGenres = (fields?: EFieldGroup[]): Promise<IGenre[]> =>
    this.api.get(`/genres`, {}, fields);

  getSubgenres = (
    genre: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IGenre[]> =>
    this.api.get(`/genres/${genre}/subgenres`, {}, fields);

  getItem = (genre: TIdentifier, fields?: EFieldGroup[]): Promise<IGenre> =>
    this.api.get(`/genres/${genre}`, {}, fields);
}
