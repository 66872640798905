import React, {FC, useEffect, useState} from 'react';
import './header.scss';
import session from '../../modules/session';
import {observer} from 'mobx-react';
import logo from '../../assets/images/logo.png';
import {useTranslation} from 'react-i18next';
import {NavLink, Link} from "react-router-dom";
import HeaderProfile from "./HeaderProfile";
import Notifications from "../Notifications";
import {formatNumber} from "../../modules/utils";


interface Props {

}

const mainMenu = [
  {path: '/', title: 'MY_TRACKS'},
  {path: '/reports', title: 'REPORTS'},
  {path: '/billing', title: 'BILLING'},
  {path: '/help', title: 'HELP'},
]

const Header: FC<Props> = () => {
  const {t} = useTranslation();
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setScrolling(scrollY >= 20)
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={scrolling ? 'scrolling' : ''}>
      <div className="header">
        <div className='d-flex'>
          <Link to={'/'}>
            <img src={logo} alt="logo" className='logo'/>
          </Link>
          <nav className='nav'>
            {mainMenu.map(item => (
              <NavLink key={item.path} to={item.path}>{t(item.title)}</NavLink>
            ))}
          </nav>
        </div>
        <div className='d-flex align-items-center align-self-center'>
          <div className='balance text-muted-14 text-semiBold'>
            <div className=''>{t('BALANCE')}</div>
          </div>
          <div className='ms-2 bold me-5'>$ {formatNumber(session.user?.balance || 0, 2)}</div>
          <Notifications/>
          <HeaderProfile/>
        </div>
      </div>
    </header>
  )
    ;
};


export default observer(Header);
