import React, {FC, useCallback, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Input, SearchInput} from "../../components/FormControls";
import {IGetStatsRequest} from "./ReportPage";
import {ReactSVG} from "react-svg";
import chevron_svg from '../../assets/images/icons/caret_top.svg';
import filter_svg from '../../assets/images/icons/filter.svg';
import eye_svg from '../../assets/images/icons/eye.svg';
import eye_doll_svg from '../../assets/images/icons/eye_doll.svg';
import dollar_svg from '../../assets/images/icons/dollar.svg';
import dollar_crm_svg from '../../assets/images/icons/dollar_crm.svg';
import {formatViews} from "../../modules/utils";


const groupData = {
  track: 'TRACKS',
  country: 'COUNTRIES',
  date: 'MONTHS',
}

interface Props {
  request: any;
  updateState: (request: Partial<IGetStatsRequest>) => void;
  pager: any
}

const ReportsFilters: FC<Props> = ({request, updateState, pager}) => {
  const {t} = useTranslation();
  const [filtersVisible, setFiltersVisible] = useState(false);

  const filtersCount = useMemo(() => {
    let count = 0;
    if (request.periodBegin || request.periodEnd) count++;
    return count;
  }, [request]);

  const handleGroup = useCallback((group: IGetStatsRequest['group']) => () => {
    if (group === request.group) return;
    updateState({group})
  }, [updateState, request.group]);

  const toggleFiltersVisible = useCallback(() => {
    setFiltersVisible(prevState => !prevState);
  }, []);

  return (
    <div className='report-filters'>
      <section className='report-filters-group'>
        {Object.entries(groupData).map(([group_by, title]) => (
          <div
            className={`report-filters-group-item${request.group === group_by ? ' active' : ''}`}
            key={group_by}
            onClick={handleGroup(group_by as IGetStatsRequest['group'])}
          >
            {t(title)}
          </div>
        ))}
      </section>
      <hr className='m-0 mb-4'/>

      <section className='d-flex justify-content-between'>
        <div className='d-flex'>
          <button className={`btn btn-lg ${filtersVisible ? 'btn-primary' : 'btn-outline-primary'} pe-3 me-3`}
                  type={'button'}
                  onClick={toggleFiltersVisible}>
            <span className='me-2 pe-1'>{t('FILTER')}</span>
            <ReactSVG src={filtersVisible ? chevron_svg : filter_svg} className='react-icon'/>
          </button>
          <SearchInput onSearch={(query) => updateState({query})} className='sm'/>
        </div>
        <div className="d-flex">
          <div className="d-flex me-5">
            <ReactSVG src={eye_svg} className='report-icon react-icon' />
            <div>
              <div className='text-muted-14 text-medium'>{t('VIEWS')}</div>
              <div className='text-bold mt-2px'>{formatViews(pager?.totalViews || 0)}</div>
            </div>
          </div>
          <div className="d-flex me-4">
            <ReactSVG src={eye_doll_svg} className='report-icon react-icon' />
            <div>
              <div className='text-muted-14 text-medium'>{t('COM_views')}</div>
              <div className='text-bold mt-2px'>{formatViews(pager?.monetizedViews || 0)}</div>
            </div>
          </div>
          <div className="d-flex me-5">
            <ReactSVG src={dollar_svg} className='report-icon react-icon' />
            <div>
              <div className='text-muted-14 text-medium'>{t('INCOME')}</div>
              <div className='text-bold mt-2px'>{formatViews(pager?.profit || 0)}</div>
            </div>
          </div>
          <div className="d-flex">
            <ReactSVG src={dollar_crm_svg} className='report-icon react-icon' />
            <div>
              <div className='text-muted-14 text-medium'>{t('CPM')}</div>
              <div className='text-bold mt-2px'>{formatViews(pager?.cpm || 0)}</div>
            </div>
          </div>
        </div>
      </section>

      <section className='report-filters-sort'>
        <div className='d-flex p-12'>
          <div className='mb-3 input-group'>
            <Input
              type={'date'}
              className='sm'
              label={'PERIOD'}
              placeholder={t('FROM') || ''}
              value={request.periodBegin}
              // onChange={(e) => runInAction(() => (st.request.passport!.series = e.target.value))}
            />
            <Input
              type={'date'}
              className={'sm'}
              placeholder={t('TO') || ''}
              value={request.periodEnd}
              // onChange={(e) => runInAction(() => (st.request.passport!.number = e.target.value))}
            />
          </div>
        </div>
        <div className='d-flex align-self-start'>
          <button className='btn btn-outline-primary btn-sm' type='button'>
            {t('RESET_ALL')}
          </button>
        </div>
      </section>


    </div>
  );
}

export default ReportsFilters;