import React, {FC, ReactNode, useCallback} from 'react';
import moment from "moment";
import {useTranslation} from "react-i18next";
import {formatNumber} from "../../modules/utils";
import Player from "../../components/Player";
import {IGetStatsRequest} from "./ReportPage";
import {ITrack} from "../../modules/rest";

interface Props {
  item: any;
  index: number;
  group: IGetStatsRequest['group'];
  sort: IGetStatsRequest['sort'];
}

const ReportsListItem: FC<Props> = ({item, index, group, sort}) => {
  const {t} = useTranslation();
  const renderItem = useCallback((): ReactNode => {
    if (group === 'country') return (
      <div className='d-flex align-items-center bold'>
        <div className='circle-light-48 stats__list__item__country'/>
        {t(`countries:${item.item}`)}
      </div>
    )
    if (group === 'date') return (
      <div className='text-capitalize d-flex align-items-center bold'>
        <div className='circle-light-48 stats__list__item__calendar'/>
        {moment(item?.item as string).format("MMMM (YYYY)")}
      </div>
    )
    if (group === 'track') return <Player track={item?.item || {} as ITrack}/>;
    return null;
  }, [item]);

  return (
    <div className='report__list__item'>
      <div className='report__list__item__id text-muted'>
        #{index + 1}
      </div>
      <div className='report__list__item__content'>
        <div className="report__list__item__content__col">{renderItem()}</div>
          <div className={`report__list__item__content__col ${sort !== 'totalViews' ? ' text-muted' : ''}`}>{formatNumber(item.totalViews!)}</div>
        <div className={`report__list__item__content__col ${sort !== 'monetizedViews' ? ' text-muted' : ''}`}>{formatNumber(item.monetizedViews)}</div>
        <div className={`report__list__item__content__col ${sort !== 'profit' ? ' text-muted' : ''}`}><span className="bold">{formatNumber(item.profit, 2)} ₽</span><span className="ms-2 small text-muted">≈ $ {item.profitUsd?.toFixed(2)}</span></div>
        <div className={`report__list__item__content__col ${sort !== 'cpm' ? ' text-muted' : ''}`}>{item.cpm !== null ? <><span className="bold">{formatNumber(item.cpm! , 2)} ₽</span><span className="ms-2 small text-muted">≈ $ {item.cpmUsd?.toFixed(2)}</span></> : null}</div>
      </div>
    </div>
  );
}

export default ReportsListItem;
