import {EVideoStatus, IVideo} from '../../../modules/rest';
import {formatViews, thumb} from '../../../modules/utils';
import trash_svg from '../../../assets/images/icons/trash.svg';
import youtube_outline_svg from '../../../assets/images/icons/youtube_outline.svg';
import stub from '../../../assets/images/icons/video_stub.svg';

import React, {FC, useCallback, useState} from 'react';
import {confirmDialog} from '../../../modules/confirm';
import {API} from '../../../modules/api';
import {toast} from 'react-toastify';
import {useTranslation} from "react-i18next";
import {Checkbox} from "../../../components/FormControls";
import Loadable from "../../../components/Loadable";
import {VideoStatuses} from "../../../modules/directory";
import ModalDropdown from "../../ModalDropdown";
import {ReactSVG} from "react-svg";

interface Props {
  onChanged: () => void;
  video: IVideo;
  selectedVideosIds: number[];
  onSelectVideo: (id: number) => void;
}

const TrackVideoItem: FC<Props> = ({video, onChanged, selectedVideosIds, onSelectVideo}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  const cutoutTrack = useCallback(() => {
    confirmDialog(t('CUT_TRACK_CONFIRM_TITLE', {title: video.title}), {
      title: t('CUTTING_TRACK') || '',
      danger: true,
      confirmText: t('TRACK_CUT') || '',
    }).then((confirmed) => {
      if (!confirmed) return;
      setLoading(true);
      API.VideoEmbeds.cutYoutubeVideo(video.id)
        .then(() => {
          toast.success(t('TRACK_CUT_SUCCESS'));
          onChanged();
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    });
  }, [setLoading, video, onChanged, t]);

  const deleteVideo = useCallback(() => {
    confirmDialog(t('DELETE_CLIP_CONFIRM_TITLE', {title: video.title}), {
      title: t('DELETING_CLIP') || '',
      danger: true,
      confirmText: t('REMOVE') || '',
    }).then((confirmed) => {
      if (!confirmed) return;
      setLoading(true);
      API.VideoEmbeds.deleteYoutubeVideo(video.id)
        .then(() => {
          toast.success(t('CLIP_REMOVED'));
          onChanged();
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    });
  }, [setLoading, video, onChanged]);

  const openVideo = useCallback(() => {
    window.open(`https://www.youtube.com/watch?v=${video.extId}`);
  }, [video.extId]);

  const openStudio = useCallback(() => {
    window.open(`https://studio.youtube.com/video/${video.extId}/edit`);
  }, [video.extId]);

  const checked = selectedVideosIds.includes(video.id);

  return (
    <Loadable
      loading={loading}
      className={`my__tracks__modal__video__list__item${checked ? ' active' : ''}${checked || selectedVideosIds?.length ? ' checked' : ''}${!video.hasTrack || video.status !== EVideoStatus.Public ? ' disabled' : ''}`}>
      <div className='my__tracks__modal__video__list__item-id text-muted-14 text-medium'>
        #{video.id}
      </div>
      <div className='my__tracks__modal__video__list__item-checkbox text-muted-14'>
        <Checkbox checked={checked} onChange={() => onSelectVideo(video.id)}/>
      </div>
      <div className='d-flex align-items-center flex-grow-1 cursor-pointer' onClick={openVideo}>
        <div className="my__tracks__modal__video__list__item-preview">
          <img
            alt="video"
            className=""
            src={video.cover ? thumb(video.cover!.id, 180) : stub}
          />
        </div>
        <div className="my__tracks__modal__video__list__item-title text-muted text-bold-14 text-truncate-2">
          {video.title || video.extId}
        </div>
      </div>
      <div style={{minWidth: 150, width: 150}} className='text-muted-14 text-medium'>
        {t(video.hasTrack ? VideoStatuses[video.status] : 'TRACK_CUT')}
      </div>
      <div style={{minWidth: 150, width: 150}} className='d-flex justify-content-between align-items-center'>
        {video.status === EVideoStatus.Public ? <span className="me-2 text-muted-14">{formatViews(video.views)}</span> : <span></span>}
        <ModalDropdown>
          <div onClick={openStudio} className={`modal-dropdown-hidden-item`}>
            <ReactSVG src={youtube_outline_svg} className='react-icon'/>
            <span className="text-nowrap ps-2 text-muted">{t('GO_TO_STUDIO')}</span>
          </div>
          {/*{video.hasTrack &&*/}
          {/*  <div onClick={cutoutTrack} className={`modal-dropdown-hidden-item`}>*/}
          {/*    <ReactSVG src={cut_svg} className='react-icon'/>*/}
          {/*    <span className="text-nowrap text-muted ps-2">{t('TRACK_CUT')}</span>*/}
          {/*  </div>*/}
          {/*}*/}
          <div onClick={deleteVideo} className={'modal-dropdown-hidden-item text-danger'}>
            <ReactSVG src={trash_svg} className='react-icon'/>
            <span className="text-nowrap ps-2">{t('REMOVE')}</span>
          </div>
        </ModalDropdown>
      </div>
    </Loadable>
  );
};

export default TrackVideoItem;
