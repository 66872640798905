import {IVideoEmbed, IVideo, IGenerateVideoEmbedTextsTask, ELanguage} from '../../modules/rest';
import asyncModal from 'react-async-modal';
import React, {FC, FormEvent, useCallback, useState} from 'react';
import Loadable from '../../components/Loadable';
import {API} from '../../modules/api';
import {toast} from 'react-toastify';
import {Checkbox, Input} from '../../components/FormControls';
import {useTranslation} from "react-i18next";
import ModalClose from "../ModalClose";
import Button from "../../components/Button";
import CopyToClipboard from "../../components/CopyToClipboard";
import link_svg from '../../assets/images/icons/link.svg';
import ImportantBlock from "../../components/ImportantBlock";
import TextGenerate from "../../components/TextGenerate";

interface Props {
  ve: IVideoEmbed;
  resolve(video: IVideo|null): void;
}

const AddVideoToTrackModal: FC<Props> = ({ve, resolve}) => {
  const {t} = useTranslation();
  const [url, setUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showForce, setShowForce] = useState<boolean>(false);
  const [force, setForce] = useState<boolean>(false);
  const [generateTexts, setGenerateTexts] = useState<any>({});

  const handleClose = useCallback(() => {
    resolve(null)
  }, [resolve]);

  const handleUpdateGenTexts = useCallback((text: IGenerateVideoEmbedTextsTask, language: ELanguage) => {
    setGenerateTexts((prevState: any) => ({...prevState, [language]: text}));
  }, []);

  const submit = useCallback((e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    API.VideoEmbeds.addYoutubeVideo(ve.id, {url, force})
      .then((video) => {
        toast.success(t('VIDEO_ADDED_SUCCESS'));
        resolve(video);
      })
      .catch((error) => {
        toast.error(error);
        if (API.getStatusCode() === 404) {
          setShowForce(true);
        }
      })
      .finally(() => setLoading(false));
  }, [url, ve.id, resolve, force]);

  return (
    <>
      <ModalClose onClick={handleClose}/>
      <Loadable loading={loading}>
        <form onSubmit={submit}>
          <h2 className='modal-title'>{t('ADD_VIDEO')}</h2>
          <div className='text-semiBold mb-3'>{t('ADD_VIDEO_TEXT')}</div>
          <Input
            icon={link_svg}
            className='mb-3'
            label='LINK_TO_VIDEO'
            required
            type='url'
            placeholder={'https://www.youtube.com/watch?v=UtakVRO4u9A'}
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
              setShowForce(false);
            }}
          />

          {showForce &&
            <div className={`mb-3 video-exist${force ? ' active' : ''}`}>
              <Checkbox checked={force} onChange={e => setForce(e.target.checked)} label='VIDEO_EXIST'/>
              <div className='text-muted-14 mt-2 ps-4 ms-2'>{t('VIDEO_EXIST_TEXT')}</div>
            </div>
          }
          <div className='text-medium mb-2'>{t('CLIPBOARD_TITLE')}</div>
          <TextGenerate
            videoEmbedId={ve.id}
            onUpdate={handleUpdateGenTexts}
            generateTexts={generateTexts}
          />

          <ImportantBlock text={'VIDEO_EMBED_IMPORTANT_TEXT'}  title='VIDEO_EMBED_IMPORTANT_TITLE' className='my-4' />
          {/*<CopyToClipboard links={ve.track?.album?.links?.array}/>*/}

          <div className="modal-footer-btns">
            <Button
              text='CANCEL'
              onClick={handleClose}
              color='outline-primary'
            />
            <Button
              text='ADD'
              type='submit'
            />
          </div>
        </form>
      </Loadable>
    </>
  );
};

const addVideoModal = (ve: IVideoEmbed): Promise<IVideo|null> => asyncModal(AddVideoToTrackModal, {ve}, {showCloseIcon: false});

export {addVideoModal};
