import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {observer} from 'mobx-react';

import session from './modules/session';
import Loader from './components/Loader';
import {Login, Password} from './routes/auth';
import Header from './components/Header';
import TracksPage from "./routes/tracks/TracksPage";
import ReportPage from "./routes/report/ReportPage";
import BillingPage from "./routes/billing";
import FaqPage from "./routes/faq";


const App = observer(() => {


  if (!session.ready)
    return (
      <div className='app p-5 d-flex justify-content-center'>
        <Loader text='SESSION_RESTORE'/>
      </div>
    );
  if (!session.user) return (
    <Routes>
      <Route path='/login' element={<Login/>}/>
      <Route path='/password' element={<Password/>}/>
      <Route path='*' element={<Navigate to='/login' replace/>}/>
    </Routes>
  )
  return (
    <>
      <Header/>
      <main>
        <Routes>
          <Route path='/' element={<TracksPage/>}/>
          <Route path='/reports' element={<ReportPage/>}/>
          <Route path='/billing' element={<BillingPage/>}/>
          <Route path='/help' element={<FaqPage/>}/>
          <Route path={'/help/:id'} element={<FaqPage/>}/>
          <Route path='*' element={<Navigate to='/' replace/>}/>
        </Routes>
      </main>
    </>
  );
});

export default App;
