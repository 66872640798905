import React, {useCallback, useEffect} from 'react';
import {observer, useLocalObservable} from "mobx-react";
import {runInAction} from "mobx";
import './billing.scss'
import Loader from "../../components/Loader";
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import billing_stub from "../../assets/images/icons/billing_stub.svg";




interface State {
  // pager?: IGetStatsResponse<unknown>;
  // request: IGetStatsRequest;
  pager?: any;
  loading: boolean;
  ready: boolean;
}

// const startPeriod = moment().add('month', -3).format('YYYY-MM');

const BillingPage = observer(() => {
  const {t} = useTranslation();
  const st = useLocalObservable<State>(() => ({
    ready: false,
    loading: false,

    tracksFilter: [],
  }));

  useEffect(() => {
    fetch();
  }, []);

  const fetch = useCallback(() => {
    runInAction(() => (st.loading = true));
    // API.Stats.getTracksStats(st.request)
    //   .then((pager) => {
    //     runInAction(() => {
    //       st.pager = pager;
    //       cache.set('owner.tracks', st.pager);
    //     });
    //   })
    //   .catch((e) => toast.error(e, {toastId: 'stats_fetch'}))
    //   .finally(() => runInAction(() => {
    //     st.loading = false;
    //     st.ready = true;
    //   }));
  }, [st]);


  return (
    <>
      <div className='page-header'>
        <h1 className='page-title'>{t('BILLING')}</h1>
      </div>
      {!st.ready
        ?
        <div className='d-flex flex-column align-items-center'>
          <ReactSVG src={billing_stub}  className='react-icon'/>
          <h4 className='mt-4 mb-3 text-muted'>{t('BILLING_EMPTY')}</h4>
        </div>
        // <Loader/>
        :
        <>

        </>
      }
    </>
  );
});

export default BillingPage;
