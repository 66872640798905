import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import warning from '../assets/images/icons/warning.svg';
import {useTranslation} from "react-i18next";

interface Props {
  title?: string;
  text: string;
  className?: string;
}

const ImportantBlock: FC<Props> = ({title, text, className}) => {
  const {t} = useTranslation();
  return (
    <div className={`important-block ${className || ''}`}>
      <div className='d-flex align-items-center mb-2'>
        <ReactSVG src={warning} className='react-icon'/>
        <h3 className='text-danger ps-2 ms-1'>{t(title || 'IMPORTANT')}</h3>
      </div>
      <div className='text-medium text-muted'>{t(text)}</div>
    </div>
  );
}

export default ImportantBlock;