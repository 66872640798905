import React, {ButtonHTMLAttributes, FC} from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {Icon} from './fontawesome';
import {useTranslation} from 'react-i18next';
import Loader from "./Loader";
import {ReactSVG} from "react-svg";

interface Props extends ButtonHTMLAttributes<any> {
  icon?: IconProp;
  iconSvg?: any;
  text?: string;
  size?: 'sm'|'lg'|'md'|'xs';
  color?: 'primary'|'success'|'secondary'|'danger'|'warning'|'outline-primary'|'outline-secondary';
  className?: string;
  loading?: boolean;
}

const Button: FC<Props> = ({
                             text,
                             icon,
                             iconSvg,
                             className,
                             size = 'md',
                             color = 'primary',
                             loading = false,
                             ...props
                           }) => {
  const {t} = useTranslation();
  return (
    <button
      className={`btn btn-${color} btn-${size} ${className || ''}`}
      {...props}
      type={props.type || 'button'}
    >
      {loading
        ?
        <Loader/>
        :
        <>
          {text && <span>{t(text)}</span>}
          {icon && <div className='ms-4'><Icon icon={icon} fixedWidth/></div>}
          {iconSvg && <ReactSVG src={iconSvg} className='react-icon ms-4'/>}
        </>
      }
    </button>
  );
};

export default Button;
