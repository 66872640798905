import asyncModal from 'react-async-modal';
import {FC, FormEvent, useCallback, useState} from 'react';
import Button from '../../components/Button';
import Loadable from '../../components/Loadable';
import {useTranslation} from "react-i18next";
import ModalClose from "../ModalClose";
import {Select, Textarea} from "../../components/FormControls";
import {API} from "../../modules/api";
import {toast} from "react-toastify";
import {ELanguage} from "../../modules/rest";
import {Languages} from "../../modules/directory";


interface Props {
  resolve(success: boolean): void;
}

const RequestTrackModal: FC<Props> = ({resolve}) => {
  const {t} = useTranslation();
  const [requestDescription, setRequestDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(ELanguage.English);

  const submit = useCallback((e: FormEvent) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    API.VideoEmbeds.requestVideoEmbed({comment: requestDescription, language})
      .then((res) => {
        toast.success(t('REQUEST_VIDEO_SENT'));
        resolve(true);
      })
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [loading, requestDescription]);

  const handleClose = useCallback(() => {
    resolve(false)
  }, [resolve]);

  return (
    <>
      <ModalClose onClick={handleClose}/>
      <Loadable loading={loading}>
        <h2 className='modal-title'>{t('REQUEST_VIDEO_TEXT')}</h2>
        <form onSubmit={submit}>
          <Textarea
            maxLength={512}
            required
            value={requestDescription}
            onChange={e => setRequestDescription(e.target.value)}
          />
          <div className='text-muted mt-4 mb-2 pb-1' style={{whiteSpace: 'pre-wrap'}}>{t('REQUEST_VIDEO_TEXT_2')}</div>
          <div className='w-50' style={{paddingRight: 12}}>
            <Select
              value={language}
              onChange={e => setLanguage(e.target.value)}
              className='flex-grow-1'>
              {Object.entries(Languages).map(([key, value]) => (
                <option value={key} key={key}>{t(`langs:${key}`)}</option>
              ))}
            </Select>
          </div>
          <div className="modal-footer-btns">
            <Button
              text='CANCEL'
              onClick={handleClose}
              color='outline-primary'
            />
            <Button
              text='SEND'
              type='submit'
            />
          </div>
        </form>
      </Loadable>
    </>
  );
};

const openRequestTrackModal = (): Promise<boolean> => {
  return asyncModal(RequestTrackModal, {}, {showCloseIcon: false});
};

export {openRequestTrackModal};
