import React, {useCallback, useEffect} from 'react';
import {observer, useLocalObservable} from "mobx-react";
import {ECountry, ESortOrder, ITrack} from "../../modules/rest";
import {runInAction} from "mobx";
import './report.scss'
import Loader from "../../components/Loader";
import {useTranslation} from "react-i18next";
import ReportsFilters from "./ReportsFilters";
import Empty from "../../components/Empty";
import ReportsListItem from "./ReportsListItem";
import {ReactSVG} from "react-svg";
import Button from "../../components/Button";
import get_track_svg from "../../assets/images/icons/get_track.svg";
import reports_stub from "../../assets/images/icons/reports_stub.svg";

export interface IGetStatsRequest {
  group: "track"|"country"|"date";
  trackId?: number|number[];
  countryCode?: ECountry|ECountry[];
  periodBegin?: any;
  periodEnd?: any;
  query?: string;
  sort: "totalViews"|"monetizedViews"|"profit"|"cpm";
  order: ESortOrder;
}


interface State {
  // pager?: IGetStatsResponse<unknown>;
  // request: IGetStatsRequest;
  request: any;
  pager?: any;
  loading: boolean;
  ready: boolean;
  tracksFilter: ITrack[];
}

// const startPeriod = moment().add('month', -3).format('YYYY-MM');

const ReportPage = observer(() => {
  const {t} = useTranslation();
  const st = useLocalObservable<State>(() => ({
    ready: false,
    loading: false,
    pager: {data: [{id: 1}, {id: 2}, {id: 3}]},
    request: {
      group: 'track',
      sort: 'profit',
      trackId: [] as number[],
      countryCode: [] as ECountry[],
      order: ESortOrder.DESC
    },
    tracksFilter: [],
  }));

  useEffect(() => {
    fetch();
  }, []);

  const fetch = useCallback(() => {
    runInAction(() => (st.loading = true));
    // API.Stats.getTracksStats(st.request)
    //   .then((pager) => {
    //     runInAction(() => {
    //       st.pager = pager;
    //       cache.set('owner.tracks', st.pager);
    //     });
    //   })
    //   .catch((e) => toast.error(e, {toastId: 'stats_fetch'}))
    //   .finally(() => runInAction(() => {
    //     st.loading = false;
    //     st.ready = true;
    //   }));
  }, [st]);


  return (
    <>
      <div className='page-header'>
        <h1 className='mb-0 page-title'>{t('REPORTS')}</h1>
      </div>
      {!st.ready
        ?
        <div className='d-flex flex-column align-items-center'>
          <ReactSVG src={reports_stub}  className='react-icon'/>
          <h4 className='mt-4 mb-3 text-muted'>{t('REPORT_EMPTY')}</h4>
        </div>
        // <Loader/>
        :
        <>
          <ReportsFilters
            request={st.request}
            pager={st.pager}
            updateState={(request: Partial<IGetStatsRequest>) => {
              runInAction(() => {
                st.request = {...st.request, ...request}
                fetch();
              })
            }}
          />
          {st.pager?.data?.length ?
            <div className='row mb-3'>
              {st.pager.data?.map((item: any, i : number) => (
                <ReportsListItem
                  item={item}
                  key={i}
                  index={i}
                  group={st.request.group}
                  sort={st.request.sort}
                />
              ))}
            </div>
            :
            <Empty show/>
          }
        </>
      }
    </>
  );
});

export default ReportPage;
