import React, {FC, useCallback, useRef, useState} from 'react';
import {Select} from "../FormControls";
import lang_svg from "../../assets/images/icons/lang.svg";
import {Languages} from "../../modules/directory";
import Button from "../Button";
import {useTranslation} from "react-i18next";
import {API} from "../../modules/api";
import {ELanguage, IGenerateVideoEmbedTextsTask} from "../../modules/rest";
import TextGenerateResultItem from "./TextGenerateResultItem";

interface Props {
  videoEmbedId: number;
  onUpdate: (text: IGenerateVideoEmbedTextsTask, language: ELanguage) => void;
  generateTexts: any;
}

const TextGenerate: FC<Props> = ({videoEmbedId, generateTexts, onUpdate}) => {
  const ping: any = useRef(null);
  const {t} = useTranslation();
  const [language, setLanguage] = useState<ELanguage>(ELanguage.English);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);


  const pingTask = useCallback(async (id: number|string) => {
    try {
      const task = await API.Tasks.getTask<IGenerateVideoEmbedTextsTask>(id);
      if (task.status === 'finished') {
        onUpdate(task, language);
        setLoading(false);
        clearTimeout(ping.current);
      } else if (task.status === 'failed') {
        setError(true);
        setLoading(false);
        clearTimeout(ping.current);
      } else {
        ping.current = setTimeout(() => pingTask(task.id!), 1000);
      }
    } catch (e) {
      setError(true);
      setLoading(false);
      clearTimeout(ping.current);
    } finally {

    }
  }, [language]);

  const handleGenerateClick = useCallback(async () => {
    setError(false);
    setLoading(true);
    try {
      const task = await API.Tasks.generateVideoEmbedTexts({videoEmbedId, language})
      ping.current = setTimeout(() => pingTask(task.id!), 1000);
    } catch (e) {
    }
  }, [language, videoEmbedId]);

  const activeText = generateTexts[language];

  return (
    <>
      <div className='text-generate-bg align-items-end'>
        <Select
          disabled={loading}
          icon={lang_svg}
          value={language}
          onChange={e => setLanguage(e.target.value)}
          label='TEXT_LANGUAGE'
          className='sm flex-grow-1 me-2 w-50'>
          {Object.entries(Languages).map(([key, value]) => (
            <option value={key} key={key}>{t(`langs:${key}`)}</option>
          ))}
        </Select>
        <Button
          disabled={activeText || loading}
          loading={loading}
          className='flex-grow-1 w-50 ms-2'
          size='lg'
          text='GET_TEXT'
          onClick={handleGenerateClick}
          color='outline-primary'
        />
      </div>
      {Boolean(activeText || loading || error) &&
        <div className="text-generate-separator">
          {Array(8).fill({}).map((_, i) => (
            <div className="text-generate-separator-item" key={i}/>
          ))}
        </div>
      }
      {loading &&
        <div className="text-generate-bg align-items-center flex-column">
          <div className="generate-text-loading-fingers">
            {Array(4).fill({}).map((item, i) => (
              <div className="generate-text-loading-finger" key={i}>
                <div className="generate-text-loading-finger-nail"/>
              </div>
            ))}
            <div className="generate-text-loading-finger"/>
          </div>
          <div className='text-muted'>{t('WAIT_TEXT')}</div>
        </div>
      }
      {error && (
        <div className='text-generate-bg generate-text-error'>
          <div className='mb-2'>{t('ERROR_GENERATE_TEXT')}</div>
          <div className='text-tint text-medium cursor-pointer' onClick={handleGenerateClick}>
            {t('TRY_AGAIN')}
          </div>
        </div>
      )}
      {activeText
        ?
        <div className="text-generate-bg flex-column">
          <h5 className='mb-3'>{t('GENERATE_TEXT_TITLE')}</h5>
          <div className='d-flex flex-wrap'>
            <TextGenerateResultItem  label={t('ADV_TEXT')} text={activeText.advText} className='me-2' />
            <TextGenerateResultItem  label={t('REQ_TEXT')} text={activeText.reqText}  className='ms-2' />
            <TextGenerateResultItem  label={t('DESC_TEXT')} text={activeText.descText} className='w-100 mt-3' />
          </div>
        </div>
        :
        null
      }
    </>
  );
}

export default TextGenerate;