import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import Button from "../../components/Button";
import get_track_svg from "../../assets/images/icons/get_track.svg";
import {ReactSVG} from "react-svg";
import settings from './assets/settings.svg';

interface Props {
  onClick: () => void
}

const VideosListEmpty: FC<Props> = ({onClick}) => {
  const {t} = useTranslation();

  return (
    <div className='videos-list-empty'>
      <div className="videos-list-empty-content">
        <ReactSVG src={settings} className='react-icon videos-list-empty-icon'/>
        <h2 className='text-24 mb-4'>{t('HOW_IT_WORK')}</h2>
        <div>
          {Array(5).fill({}).map((_, i) => (
            <div className="videos-list-empty-item" key={i}>
              <div className='videos-list-empty-content-item-number'>{i + 1}</div>
              <div className='videos-list-empty-item-content'>{t(`VIDEOS_EMPTY_TEXT_${i + 1}`)}</div>

            </div>
          ))}
        </div>
        <Button
          className='videos-list-empty-btn'
          text='REQUEST_VIDEO'
          iconSvg={get_track_svg}
          onClick={onClick}
        />
      </div>
    </div>
  );
}

export default VideosListEmpty;