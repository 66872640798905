import React, { FC, InputHTMLAttributes, useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import lock_svg from '../../assets/images/icons/lock.svg';
import eye_svg from '../../assets/images/icons/eye.svg';
import eyelashes_svg from '../../assets/images/icons/eyelashes.svg';

interface Props extends InputHTMLAttributes<any> {
  label?: string;
  className?: string;
  error?: boolean;
  onForgotPassword?: () => void;
}

const InputPassword: FC<Props> = ({
                                    label,
                                    className,
                                    onForgotPassword,
                                    error,
                                    ...props
                                  }) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const toggleVisible = useCallback(() => {
    setVisible(visible => !visible);
  }, []);
  return (
    <div className={`form-group${error ? ' error' : ''} ${className || ''}`}>
      <div className='d-flex justify-content-between'>
        <label className={`muted mb-2`}>
          <span>{t('PASSWORD')}</span>
          {props.required ? <span className='text-danger text-12'>*</span> : null}
        </label>
        {onForgotPassword ? <div className='form-password-forgot' onClick={onForgotPassword}>{t('FORGOT_PASSWORD')}</div> : null }
      </div>
      <div className='form-control-wrap'>
        <input
          className={`form-control form-control-icon`}
          {...props}
          type={visible ? 'text' : 'password'}
          placeholder={t('PASSWORD_PLACEHOLDER') || ''}
          name={String(props?.value)}
          autoComplete='new-password'
        />
        <div className='form-control-border' />
        <div className='form-control-gradient' />
        <div className='input-icon'><ReactSVG src={lock_svg} /></div>
        <div className='input-pass-eye' onClick={toggleVisible}>
          <ReactSVG src={visible ? eye_svg : eyelashes_svg} />
        </div>
      </div>
    </div>
  );
};

export default InputPassword;