import React, {FC} from 'react';
import {Icon} from "../components/fontawesome";
import dots_svg from '../assets/images/icons/dots.svg';
import {ReactSVG} from "react-svg";

interface Props {
  children: any
}

const ModalDropdown: FC<Props> = ({children}) => {
  return (
      <div className='modal-dropdown'>
        <div className="modal-dropdown-content">
          <ReactSVG src={dots_svg} className='react-icon' />
        </div>
        <div className="modal-dropdown-hidden">
          {children}
        </div>
      </div>
  );
}

export default ModalDropdown;